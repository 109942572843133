import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import {withRouter} from 'react-router';
import statick from "../../const/index";
import {Button, CardBody, Col, Container, Pagination, PaginationItem, PaginationLink, Row} from "reactstrap";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"
import Bar from "../../components/bar/index";
import {Link} from "react-router-dom";



const retContent = (item,i,lang,statickLang)=>{

    if(lang === "uz"){
        return(
            <div>
                <h4 className="py-4 text-uppercase font-weight-bold">{item.journal.titleUz}</h4>
                {
                    item.description.length !==0?
                        <div className="journal-text mt-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: item.description[i-1].descriptionUz}}/>
                        :<h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>

                }
            </div>

        )
    }else  if(lang === "ru"){
        return(
            <div>
                <h4 className="py-4 text-uppercase font-weight-bold">{item.journal.titleRu}</h4>
                {
                    item.description.length !==0?
                        <div className="journal-text mt-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: item.description[i-1].descriptionRu}}/>
                        :<h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>

                }
            </div>

        )
    }else  if(lang === "eng"){
        return(
            <div>
                <h4 className="py-4 text-uppercase font-weight-bold">{item.journal.titleEng}</h4>
                {
                    item.description.length !==0?
                        <div className="journal-text mt-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: item.description[i-1].descriptionEng}}/>
                        :<h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>

                }
            </div>

        )
    }

};

class Journal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? this.props.match.params.id :false,
            journal: [],
            pagination: 1,
            gallery: []
        }
    }
    componentDidMount(){
        if(this.state.id){
            try{
                axios.get(statick.serverUrl+"/api/journal/"+this.state.id)
                    .then(journal=>{
                        console.log(journal);
                        this.setState({
                            journal: journal.data,
                            gallery: journal.data.journal.imgId
                        });
                    }).catch(e=>{
                    console.log(e);
                });
            }catch(e) {
               console.log(e)
            }
        }else{
            try{
                axios.get(statick.serverUrl+"/api/journal/")
                    .then(journal=>{
                        this.setState({
                            journal: journal.data
                        });
                    }).catch(e=>{
                    console.log(e);
                });
            }catch(e) {
                console.log(e)
            }
        }
    }
    changPagination =(i)=>{
        this.setState({
            pagination: i+1
        })
    };
    pagination=(status)=>{
        let i = this.state.pagination;
        status ? i++ : i--;
        if(status && i < this.state.journal.description.length){
            this.setState({
                pagination: i
            })
        }else if(!status && i > 1){
            this.setState({
                pagination: i
            })
        }
        console.log(status, i)
    };
    setRoute=(id)=>{
        console.log(id)
        try{
            axios.get(statick.serverUrl+"/api/journal/"+id)
                .then(journal=>{
                    this.setState({
                        journal: journal.data,
                        gallery: journal.data.journal.imgId,
                        id:id
                    });
                }).catch(e=>{
                console.log(e);
            });
        }catch(e) {
            console.log(e)
        }
    }
    render() {
        const statickLang = this.props.statickLang;
        const lang = this.props.lang;
        const images = this.state.gallery;
        let gallery=[];
        if(images.length !=0){
            images.forEach(item=>{
                gallery.push({
                    original: statick.serverUrl+"/api/images/"+item,
                    thumbnail:  statick.serverUrl+"/api/images/"+item,
                })
            });
        }
        if(this.state.id){
            return (
                <div className="single-journal">
                    <Container className="py-4 bg-white">
                        <Row>
                            <Col md="9">
                                <div className="px-5 border-bottom border-light">
                                    {
                                        this.state.journal.length !==0 ?
                                            retContent(this.state.journal,this.state.pagination,lang,statickLang)
                                            :
                                            <h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>

                                    }
                                    {
                                        this.state.journal.length !==0 ?
                                            this.state.journal.description.length !==0 ?
                                                <Pagination aria-label="Page navigation example">
                                                    <PaginationItem>
                                                <span className="page-link">
                                                    {statickLang.PAGES}:
                                                </span>
                                                    </PaginationItem>
                                                    {
                                                        this.state.journal.description.map((item,i)=>{
                                                            return(
                                                                <PaginationItem onClick={()=>this.changPagination(i)} active={i+1 === this.state.pagination ? true : false}>
                                                            <span className="page-link" >
                                                                {i+1}
                                                            </span>
                                                                </PaginationItem>
                                                            )
                                                        })
                                                    }
                                                </Pagination>
                                                :"":""
                                    }
                                </div>
                            <div>
                                <h4 className="text-muted text-uppercase py-4">
                                    {statickLang.PHOTO}
                                </h4>
                                {this.state.gallery.length!=0?
                                    <ImageGallery items={gallery} thumbnailPosition={"left"}/>:""}
                            </div>
                            </Col>
                            <Col md="3">
                                <Bar lang={this.props.lang} statickLang={this.props.statickLang}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }else{
            return (
                <div className="all-journal">
                    <Container className="py-4 bg-white">
                        <Row>
                            <Col md="9">
                                <h4 className="px-4 py-3 text-muted text-uppercase">Barcha jurnallar</h4>
                                {
                                    this.state.journal.length!=0 ?
                                        this.state.journal.map((item,i)=>{
                                                if(lang=='uz'){
                                                    return(
                                                        <CardBody className="border-light border-bottom mb-4">
                                                            <Row>
                                                                <Col md="4">
                                                                    <img className="img-fluid" src={statick.serverUrl+"/api/images/"+item.journal.id} alt={item.journal.title}/>
                                                                </Col>
                                                                <Col md="8">
                                                                    <h4 className="text-left font-weight-bold">
                                                                        {item.journal.titleUz}
                                                                    </h4>
                                                                    {
                                                                        item.description.length !==0 ?
                                                                            <div className="journal-text mt-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: item.description[0].descriptionUz}}/> :
                                                                            <h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>
                                                                    }
                                                                    <p className="text-right">
                                                                        <Button color="outline-primary" onClick={()=>this.setRoute(item.journal.id)}>
                                                                            {statickLang.READMORE}
                                                                        </Button>
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    )
                                                }else if(lang=='ru'){
                                                    return(
                                                        <CardBody className="border-light border-bottom mb-4">
                                                            <Row>
                                                                <Col md="4">
                                                                    <img className="img-fluid" src={statick.serverUrl+"/api/images/"+item.journal.id} alt={item.journal.title}/>
                                                                </Col>
                                                                <Col md="8">
                                                                    <h4 className="text-left font-weight-bold">
                                                                        {item.journal.titleRu}
                                                                    </h4>
                                                                    {
                                                                        item.description.length !==0 ?
                                                                            <div className="journal-text mt-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: item.description[0].descriptionRu}}/> :
                                                                            <h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>
                                                                    }
                                                                    <p className="text-right">
                                                                        <Link className="btn btn-outline-primary" to={`/journal/${item.journal.id}`}>
                                                                            {statickLang.READMORE}
                                                                        </Link>
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    )
                                            }else if(lang=='eng'){
                                                    return(
                                                        <CardBody className="border-light border-bottom mb-4">
                                                            <Row>
                                                                <Col md="4">
                                                                    <img className="img-fluid" src={statick.serverUrl+"/api/images/"+item.journal.id} alt={item.journal.title}/>
                                                                </Col>
                                                                <Col md="8">
                                                                    <h4 className="text-left font-weight-bold">
                                                                        {item.journal.titleEng}
                                                                    </h4>
                                                                    {
                                                                        item.description.length !==0 ?
                                                                            <div className="journal-text mt-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: item.description[0].descriptionEng}}/> :
                                                                            <h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>
                                                                    }
                                                                    <p className="text-right">
                                                                        <Link className="btn btn-outline-primary" to={`/journal/${item.journal.id}`}>
                                                                            {statickLang.READMORE}
                                                                        </Link>
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    )
                                                }
                                        }):
                                        <h1 className="text-center py-5 text-muted">{statickLang.NOCONTENT}</h1>
                                }
                            </Col>
                            <Col md="3">
                                <Bar lang={this.props.lang} statickLang={this.props.statickLang}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }

    }
}

Journal.propTypes = {};

export default withRouter(Journal) ;
