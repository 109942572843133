import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Button, Card, CardBody, CardImgOverlay, Col, Container, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import statick from "../../../../const/index";
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {Redirect} from "react-router-dom";

class AddPhoto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem('token'),
            statusToken: true,
            allImages:[],
            image:'',
            allGallery:''
        }
    }
    componentDidMount(){
        try{
            axios.get(statick.serverUrl+"/api/attachment")
                .then(images=>{
                   this.setState({
                       allImages: images.data
                   })
                }).catch(e=>{
                console.log(e);
            });
            axios.get(statick.serverUrl+"/api/gallery")
                .then(gallery=>{
                    this.setState({
                        allGallery : gallery.data
                    })
                }).catch(e=>{
                console.log(e);
            });
        }catch (e){
            toast.error("Rasm yuklash bilan hatolik yuz berdi!")
        }
    }
    changeImage =(e)=>{
        this.setState({
            image: e.target.files[0]
        })
    }
    addImage=()=>{
        if(this.state.image){
            const formData = new FormData();
            const imagefile = this.state.image;
            formData.append("images", imagefile);
            try {
                axios.post(statick.serverUrl+"/api/attachment",formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                    }
                }).then(images=>{
                    this.setState({
                        allImages:images.data,
                        image:''
                    });
                    toast.success("Rasm qo'shildi!")
                }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }
                });
            }catch (e){
                console.log(e)
            }
        }
    }
    setGaleryImage=(e,id)=>{
        const galleryId = e.target.value;
        axios.put(statick.serverUrl+"/api/attachment/"+id, {
            galleryId: galleryId
        },{
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('token') //the token is a variable which holds the token
            }
        })
            .then(images=>{
                this.setState({
                    allImages : images.data
                })
            }).catch(e=>{
            const status = e.response.status;
            if(status === 401){
                toast.error("Iltimos tizimga qayta kiring!");
                setTimeout(()=>{
                    this.setState({
                        statusToken: false
                    })
                },1000)
            }else{
                toast.error("Rasm qo'shish bilan muamo bor!")
            }
        });
    };
    render() {
        if(this.state.statusToken ){
            return (
                <div className="dsh-add-photo">
                    <ToastContainer/>
                    <Row>
                        <Col md="6">
                            <input type="file" id="file" hidden onChange={this.changeImage}/>
                            <label htmlFor="file" className="badge badge-info py-3 px-3 d-block">Rasm yuklash</label>
                        </Col>
                        <Col md="6">
                            <Button color="primary" className="small" onClick={this.addImage}>Saqlash</Button>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            {
                                this.state.allImages.length === 0 ? <h4 className="text-center text-muted mb-0">Rasmlar mavjud emas!</h4> :
                                    <Row>
                                        {
                                            this.state.allImages.map((item,i)=>{
                                                return(
                                                    <Col md="3" className="p-2 image-block" key={i}>
                                                        <Card className="border-0">
                                                            <img src={`${statick.serverUrl}/${item.imageUrl}`} className="card-img" alt=""/>
                                                            <CardImgOverlay>
                                                                <select className="form-control small" id={item.id} onChange={(e)=>this.setGaleryImage(e,item.id)}>
                                                                    <option value="" disabled={true} selected={true}>Galereya tanlang</option>
                                                                    {
                                                                        this.state.allGallery.length===0?
                                                                            <option value="0">Iltimos galireya yarating</option>:
                                                                            this.state.allGallery.map((gallery,j)=>{
                                                                                return(
                                                                                    <option key={gallery.id} value={gallery.id} data-toggle={item.galleryId} selected={item.galleryId===gallery.id ? "selected" : ''}>{gallery.title}</option>
                                                                                )
                                                                            })
                                                                    }
                                                                </select>
                                                            </CardImgOverlay>
                                                        </Card>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                            }
                        </CardBody>
                    </Card>
                </div>
            );
        }else{
            return(
                <Redirect to="/login"/>
            )
        }

    }
}

AddPhoto.propTypes = {};

export default AddPhoto;
