import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";
import axios from "axios";
import statick from "../../const/index";
import "./index.scss";
import Bar from "../../components/bar/index";
import {Link} from "react-router-dom";
import {withRouter} from 'react-router';
import Loader from "../../components/loader/loader";
class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleId: this.props.match.params.id,
            articleData:"",
            loader:true
        }
    }
    componentDidMount() {
        const articleId = this.state.articleId;
        axios.get(statick.serverUrl+"/api/article/"+articleId)
            .then(article=>{
                this.setState({
                    articleData : article.data
                });
            }).catch(e=>{
            console.log(e);
        })
        const timeLoader = setTimeout(()=>{
            this.setState({
                loader:false
            })
        },2000)
    }
    render() {
        const article = this.state.articleData;
        const statickLang = this.props.statickLang;
        const lang = this.props.lang;
        return (
            <div className="single-article">
                {this.state.loader? <Loader/>:""}
                <Container className="bg-white py-4">
                    <Row>
                        <Col md="9">
                            {
                                article.lang === lang ?
                                    <div className="article-block">
                                        <h4 className="article-title text-uppercase py-4">{article.title}</h4>
                                        <img src={statick.serverUrl+"/api/images/"+article.imagesUrl} className="img-fluid" alt={article.title}/>
                                        <p className="m-0 text-muted">
                                            {
                                                article ? article.updatedAt.substring(8,10)+
                                                    "."+article.updatedAt.substring(5,7)+
                                                    "."+article.updatedAt.substring(0,4):
                                                    ""
                                            }
                                        </p>
                                        <div className="article-text mt-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: article.text }}/>
                                    </div>:
                                    <h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>
                            }
                            <Row>
                                <Col md="12" className="text-right py-4">
                                    <Link to="/" className="text-decoration-none">{statickLang.TOHOME}</Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="3">
                            <Bar lang={lang} statickLang={statickLang}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
Article.propTypes = {};
export default withRouter(Article);
