import React, {Component} from 'react';
import axios from "axios"
import statick from "../../const/index";
import {ToastContainer,toast} from "react-toastify";
import {Col, Container, Row} from "reactstrap";
import Bar from "../../components/bar/index";
import Loader from "../../components/loader/loader";

class Service extends Component {
    constructor(props){
        super(props);
        this.state = {
            pages: "",
            desc:"",
            loader:true

        }
    }
    componentDidMount() {
        try {
            axios.get(statick.serverUrl+"/api/page/"+3)
                .then(page=>{
                    this.setState({
                        pages:page.data
                    });
                }).catch(e=>{
                console.log(e);
            });
            axios.get(statick.serverUrl+"/api/descriptions/"+3)
                .then(desc=>{
                    this.setState({
                        desc:desc.data
                    });
                }).catch(e=>{
                console.log(e);
            });

        }catch (e){
            toast.error("Ma'lumot yuklash bilan bog'liq hatolik bor!",{
                position: toast.POSITION.TOP_CENTER
            })
        }
        const timeLoader = setTimeout(()=>{
            this.setState({
                loader:false
            })
        },2000)
    }
    title=(status)=>{
        if(status ){
            if(this.props.lang==="uz"){
                return(
                    <span>{this.state.pages.titleUz}</span>
                )
            }else if(this.props.lang==="ru"){
                return(
                    <span>{this.state.pages.titleRu}</span>
                )
            }else if(this.props.lang==="eng"){
                return(
                    <span>{this.state.pages.titleEng}</span>
                )
            }
        }else{
            if(this.props.lang==="uz"){
                return(
                    <div className="text-justify py-2 py-md-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: this.state.desc.descriptionUz }}/>
                )
            }else if(this.props.lang==="ru"){
                return(
                    <div className="text-justify py-2 py-md-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: this.state.desc.descriptionRu }}/>
                )
            }else if(this.props.lang==="eng"){
                return(
                    <div className="text-justify py-2 py-md-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: this.state.desc.descriptionEng }}/>
                )
            }
        }
    }
    render() {
        return (
            <div>
                {this.state.loader? <Loader/>:""}
                <Container className="py-5 bg-white">
                    <Row>
                        <Col md="9">
                            <h6 className="text-muted mb-0 px-4"> {this.title(true)}</h6>
                            <div className="px-3 py-md-5">
                                {
                                    this.title(false)
                                }
                            </div>
                        </Col>
                        <Col md="3">
                            <Bar lang={this.props.lang} statickLang={this.props.statikLang}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Service.propTypes = {};

export default Service;
