import React, {Component} from 'react';
import "./index.scss"

class Loader extends Component {
    render() {
        return (
            <div>
                <div className="loader">
                    <div className="loader-block">
                        <div className="loader-item"/>
                    </div>
                </div>
            </div>
        );
    }
}

Loader.propTypes = {};

export default Loader;
