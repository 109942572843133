import React, {Component} from 'react';
import * as axios  from 'axios';
import statick from "../../../../const/index";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {Redirect} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css";
import ReactTable from "react-table"
import {AvField, AvForm} from "availity-reactstrap-validation";
import AddFeedback from "./feedback";

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            feedback:[],
            about:[],
            status:true,
            image:"",
            imageUrl:'',
            feedbackStatus: true,
            updateStatus: false,
            statusToken: true,
            token: localStorage.getItem('token'),
            id:""
        };
    }
    componentDidMount() {
        try{
            axios.get(statick.serverUrl+"/api/feedback")
                .then(feedback=>{
                    this.setState({
                        feedback : feedback.data
                    });
                }).catch(e=>{
                console.log(e);
            });
            axios.post(statick.serverUrl+"/api/feedback/about")
                .then(about=>{
                    this.setState({
                        about : about.data
                    });
                }).catch(e=>{
                console.log(e);
            })
        }catch (e){
            console.log(e)
        }

    }
    setStatus=()=>{
        this.setState({
            status: !this.state.status
        })
    };
    setFeedbakStatus=(id)=>{
        if(typeof id === "number"){
            try {
                axios.get(statick.serverUrl+"/api/feedback/"+id)
                    .then(feed=>{
                        this.setState({
                            model:feed.data,
                            imageUrl:feed.data.imageUrl,
                            updateStatus:true,
                            feedbackStatus: !this.state.feedbackStatus
                        })
                    }).catch(e=>{
                    console.log(e)
                })
            }catch (e){
                console.log(e)
            }
        }else {
            try {
                axios.get(statick.serverUrl+"/api/feedback")
                    .then(feedback=>{
                        this.setState({
                            feedback : feedback.data
                        });
                    }).catch(e=>{
                    console.log(e);
                });
            }catch (e){
                console.log(e)
            }
            this.setState({
                model:{},
                imageUrl:"",
                updateStatus:false,
                feedbackStatus: !this.state.feedbackStatus
            })
        }

    };
    setImage=(e)=>{
        this.setState({
            image: e.target.files[0]
        })
    }
    addImage=()=>{
        if(this.state.image){
            const formData = new FormData();
            const imagefile = this.state.image;
            formData.append("images", imagefile);
            try {
                axios.post(statick.serverUrl+"/api/attachment",formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: this.state.token //the token is a variable which holds the token
                    }
                }).then(images=>{
                    this.setState({
                        imageUrl:images.data[0].imageUrl,
                        image:''
                    });
                    console.log(this.state.imageUrl)
                    toast.success("Rasm qo'shildi!")
                }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }
                });
            }catch (e){
                console.log(e)
            }
        }
    }
    handleSubmit(event,error,value){
        if(error.length ===0){
            try{
              axios.post(statick.serverUrl+"/api/feedback",value,{
                  headers: {
                      Authorization: this.state.token //the token is a variable which holds the token
                  }
              })
                  .then(feed=>{
                      console.log(feed)
                  }).catch(e=>{
                      console.log(e)
              })
            }catch (e){
                console.log(e)
            }
        }
    }
    //REACT TABLE SETTINGS
    columns = [{
        Header: 'Id',
        accessor: 'id' // String-based value accessors!
    }, {
        Header: 'I.F.Sh.',
        accessor: 'userName',
    }, {
        Header: 'Qisqachamazmuni',
        accessor: 'description',
    }, {
        Header: "Amallar", // Custom header components!
        Cell: props => (<div>
            <Button color="outline-info" className="mr-2"
                    onClick={() => this.setFeedbakStatus(props.original.id)}>Taxrirlash</Button>
            <Button color="outline-danger" onClick={() => this.Delete(props.original.id)}>&times;</Button>
        </div>)
    }];
    render() {
        if(this.state.statusToken){
            return (
                <div className="dsh-feedback">
                    <ToastContainer/>
                    <Row>
                        <Col md="12" className="text-right">
                            <Button color="info" onClick={this.setStatus}>Bizga murojaatlar</Button>
                        </Col>
                    </Row>
                    {
                        this.state.status ?
                            <Card className="my-4">
                                <CardBody>
                                    <Row>
                                        <Col md="12">
                                            <Button color="info" onClick={this.setFeedbakStatus}>
                                                {this.state.feedbackStatus ?  <span>Yozuv qo'shish</span>: <span>Bekor qilish</span>}
                                            </Button>
                                        </Col>`
                                    </Row>
                                    {
                                        this.state.feedbackStatus ?
                                            this.state.feedback.length!==0
                                                ?
                                                <Row className="mt-4">
                                                    <Col md="12">
                                                        <ReactTable
                                                            data={this.state.feedback} defaultPageSize={10}
                                                            previousText={'Oldingisi'}
                                                            nextText={'Keyingisi'}
                                                            loadingText={'Kuting...'}
                                                            noDataText={'Repertuarlar mavjud emas!'}
                                                            pageText={'Page'}
                                                            ofText={'dan'}
                                                            rowsText={'qator'}
                                                            columns={this.columns}
                                                        />
                                                    </Col>
                                                </Row>:
                                                <Row>
                                                    <Col md="12" className="text-center">
                                                        <h1 className="py-5 text-muted">Yozuvlar mavjud emas!</h1>
                                                    </Col>
                                                </Row>
                                            :
                                            <AddFeedback update={this.state.updateStatus} model={this.state.model}/>
                                    }
                                </CardBody>
                            </Card>:
                            <div>
                                {this.state.about.length !==0 ?
                                    <div>
                                        <Row>
                                            {this.state.about.map((item,i)=>{
                                                return(
                                                    <Col md="6" key={i} className="my-4">
                                                        <Card>
                                                            <CardBody>
                                                                <h5><b>Yuboruvchi: </b>{item.userName}</h5>
                                                                <p>
                                                                    <b>Yuboruvchi kontaktlari: </b>{item.contacts}
                                                                    <span className="px-4">
                                                             <b>Yuborilgan sana: </b>
                                                                        {

                                                                            item.updatedAt.substring(8,10)+
                                                                            "."+item.updatedAt.substring(5,7)+
                                                                            "."+item.updatedAt.substring(0,4)
                                                                        }
                                                        </span>
                                                                </p>
                                                                <p>
                                                                    <b>Murojaat mazmuni: </b>
                                                                    {item.description}
                                                                </p>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>:
                                    <div>
                                        <h1 className="text-center text-muted">Bizga murojaatlar yo'q</h1>
                                    </div>
                                }
                            </div>
                    }


                </div>
            );
        }else{
            return (
                <Redirect to="/login"/>
            );
        }

    }
}

Feedback.propTypes = {};

export default Feedback;
