import React, {Component} from 'react';
import axios from "axios"
import statick from "../../const/index";
import {Card, CardBody, Col, Container, Row} from "reactstrap";

class Repertoire extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: this.props.location.state.id,
            repertoire: {},
            allRepertoire: []
        }
    }
    componentDidMount(){
        try {
            axios.get(statick.serverUrl+"/api/repertoire/"+this.state.id)
                .then(repertoire=>{
                    this.setState({
                        repertoire: repertoire.data
                    });
                }).catch(error=>{
                console.log(error)
            });
            axios.get(statick.serverUrl+"/api/repertoire/")
                .then(repertoire=>{
                    this.setState({

                        allRepertoire: repertoire.data
                    });
                }).catch(error=>{
                console.log(error)
            })
        }catch (e){
            console.log(e)
        }


    }
    render() {
        return (
            <div>
                <Container className="py-5 px-4 bg-white">
                    <Row>
                        <Col md="12">
                            <h4>{this.state.repertoire.title}</h4>
                            <p className="text-center">
                                <img src={statick.serverUrl+"/"+this.state.repertoire.imageUrl} alt={this.state.repertoire.title} className="img-fluid"/>
                            </p>
                            <div className="article-text mt-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: this.state.repertoire.description }}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Repertoire.propTypes = {};

export default Repertoire;
