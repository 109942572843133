import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import AddPhoto from "./addphoto"
import AllGalerry from "./allGalerry"
class Photo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gallery: true,
        }
    }
    setGallery=()=>{
        this.setState({
            gallery:!this.state.gallery
        })
    };
    render() {
        return (

            <div className="dsh-photo">
                <ToastContainer/>
                <Row>
                    <Col md="6">
                        <h4 className="m-0 py-4">Foto galereyalar</h4>
                    </Col>
                    <Col md="6" className="text-right py-4">
                        <Button color="info" className="small" onClick={this.setGallery}>{this.state.gallery ?<span>Rasm Yuklash</span> : <span>Galereya</span>}</Button>
                    </Col>
                </Row>
                {this.state.gallery ?
                    <AllGalerry gallery={this.state.gallery}/>:
                    <AddPhoto/>
                }
            </div>
        );
    }
}

Photo.propTypes = {};

export default Photo;
