import React, {Component} from 'react';
import AllArticle from './allArticle';
import AddArticle from './addArticle';
//REACTSTRAP
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import '../index.scss';
class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {
            article: true,
            currentArticle: {}
        };
    }
    addArticle =(id)=>{
        this.setState({
            article : !this.state.article
        });
    };
    render() {
        return (
            <div className="dsh-article">
                <Row className="py-4">
                    <Col md="12" className="text-right">
                        <Button color="info" className="py-0 px-4" onClick={this.addArticle}>
                            {this.state.article?<small>Maqola qo'shish</small>:<small>Bekor qilish</small>}
                        </Button>
                    </Col>
                </Row>
                {this.state.article ?<AllArticle action={this.addArticle}/>:<AddArticle action={this.state.currentArticle}/>}
            </div>
        );
    }
}

Article.propTypes = {};

export default Article;
