import React, {Component} from 'react';
import axios from "axios"
import './index.scss'
import {Card, CardDeck, Col, Row} from "reactstrap";
import statick from "../../const/index";
import {Link} from "react-router-dom";

class Bar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allVideo: [],
            gallery:[]
        };
    }
    componentDidMount() {
        axios.get(statick.serverUrl+"/api/video")
            .then(video=>{
                this.setState({
                    allVideo : video.data
                });
            }).catch(e=>{
            console.log(e);
        });
        axios.get(statick.serverUrl+"/api/gallery/all")
            .then(gallery=>{
                this.setState({
                    gallery : gallery.data
                });
            }).catch(e=>{
            console.log(e);
        })
    }
    render() {
        const { allVideo } = this.state.allVideo;
        const statickLang = this.props.statickLang;
        let numVideo = 0;
        let numGalery = 0;
        return (
            <div className="bar">
                <p href="#" className="text-uppercase mb-4 pl-0 text-color nav-link">{statickLang.VIDEO}</p>
                {this.state.allVideo.length !==0 ?
                <div>
                    {
                        this.state.allVideo.map((item,i)=>{
                            if(this.props.lang === item.lang){
                                numVideo++;
                               if(numVideo <= 1){
                                   return(
                                       <Card key={i} className="border-0 rounded-0">
                                           <div className="position-relative">
                                               <img src={
                                                   this.state.allVideo.length !== 0 ? statick.serverUrl+"/"+item.imageUrl: "src/images/video-camera.svg"
                                               } className="img-fluid" alt={item.title}/>
                                               <div className="card-img-overlay">
                                                   <Link className="text-decoration-none" to={{ pathname : '/all-video', state: { videoId : item.id, lang: statickLang } }}>
                                                       <span className="icon icon-play" />
                                                   </Link>
                                               </div>
                                           </div>
                                           <ul className="mt-0 mb-2 p-0">
                                               <li className="d-inline-block text-muted small mr-auto">
                                                   {

                                                       item.updatedAt.substring(8,10)+
                                                       "."+item.updatedAt.substring(5,7)+
                                                       "."+item.updatedAt.substring(0,4)
                                                   }
                                               </li>
                                               <li className="d-inline-block text-muted small ml-5 pl-5">
                                                   <span className="icon icon-finger-up" />
                                                   {item.like}
                                               </li>
                                               <li className="d-inline-block text-muted small ml-2">
                                                   <span className="icon icon-finger-down" />
                                                   {item.dislike}
                                               </li>
                                           </ul>
                                           <h4 className="m-0">
                                               <Link className="text-decoration-none" to={{ pathname : '/all-video', state: { videoId : item.id } }}>{item.title}</Link>
                                           </h4>
                                       </Card>
                                   )
                               }else if(numVideo <=3){
                                    return(
                                        <Card key={i} className="border-0 rounded-0">
                                            <Row className="mt-4">
                                                <Col md="4">
                                                    <div className="position-relative">
                                                        <img src={
                                                            this.state.allVideo.length !== 0 ? statick.serverUrl+"/"+item.imageUrl: "src/images/video-camera.svg"
                                                        } className="img-fluid" alt={this.state.allVideo[1].title}/>
                                                        <div className="card-img-overlay">
                                                            <Link className="text-decoration-none" to={{ pathname : '/all-video', state: { videoId : item.id } }}>
                                                                <span className="icon icon-play" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="8">
                                                    <h4 className="m-0">
                                                        <Link className="text-decoration-none" to={{ pathname : '/all-video', state: { videoId : item.id } }}>
                                                            {item.title}
                                                        </Link>
                                                    </h4>
                                                </Col>
                                            </Row>
                                            <ul className="mt-1 mb-2 p-0">
                                                {

                                                    item.updatedAt.substring(8,10)+
                                                    "."+item.updatedAt.substring(5,7)+
                                                    "."+item.updatedAt.substring(0,4)
                                                }
                                                <li className="d-inline-block text-muted small ml-5 pl-5">
                                                    <span className="icon icon-finger-up" />
                                                    {item.like}
                                                </li>
                                                <li className="d-inline-block text-muted small ml-2">
                                                    <span className="icon icon-finger-down" />
                                                    {item.dislike}
                                                </li>
                                            </ul>
                                        </Card>
                                    )
                               }

                            }
                        })
                    }
                    { numVideo ===0 ?
                        <h4 className="text-center text-muted">
                            {statickLang.NOCONTENT}
                        </h4>:""}
                    <p className="text-right mb-0 py-2">
                        <Link to={{ pathname : '/all-video', state: { videoId : this.state.allVideo[0].id } }} className="text-color text-decoration-none">{statickLang.ALLVIDEO}</Link>
                    </p>
                </div>: <div><h4 className="text-muted text-center">{statickLang.NOCONTENT}</h4></div>
                }
                <p href="#" className="text-uppercase mb-4 pl-0 text-color nav-link">{statickLang.PHOTO}</p>
                <div className="gallery-block">
                    {this.state.gallery.length !== 0 && this.state.gallery[0].images.length!==0?
                        <div>
                            {
                                this.state.gallery.map((item,i)=>{
                                    if(item.gallery.lang === this.props.lang){
                                        numGalery++;
                                        if(numGalery<=1){
                                            return(
                                                <Card key={i} className="border-0 rounded-0">
                                                    {item.gallery ?
                                                        <Link to={{ pathname : '/gallery', state: { gallery : this.state.gallery, galleryId:item.gallery.id} }}>
                                                            <img src={statick.serverUrl+"/"+item.images[0].imageUrl} className="img-fluid" alt={item.gallery.title}/>
                                                        </Link>:""
                                                    }
                                                </Card>
                                            )
                                        }else if(numGalery <=3){
                                            return(
                                                <CardDeck key={i} className="mt-3">
                                                    <Card className="border-0 rounded-0">
                                                        {item ?
                                                            <Link to={{ pathname : '/gallery', state: { gallery : this.state.gallery, galleryId:item.gallery.id} }}>
                                                                <img src={statick.serverUrl+"/"+item.images[0].imageUrl} className="img-fluid" alt={item.gallery.title}/>
                                                            </Link>:""
                                                        }
                                                    </Card>
                                                </CardDeck>
                                            )
                                        }
                                    }
                                })
                            }
                            {
                                numGalery === 0 ?
                                    <h4 className="text-center text-muted">
                                    {statickLang.NOCONTENT}
                                    </h4>:""
                            }


                        </div>: <h4 className="text-center text-muted">{statickLang.NOCONTENT}</h4>
                    }

                    <p className="m-0 py-3 text-right">
                        <Link to={
                            this.state.gallery[0] ?
                                { pathname : '/gallery', state: { gallery : this.state.gallery, galleryId:this.state.gallery[0].gallery.id}}:""

                        } className="text-color text-decoration-none">{statickLang.ALLPHOTO}</Link>
                    </p>
                </div>
            </div>
        );
    }
}

Bar.propTypes = {};

export default Bar;
