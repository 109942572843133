import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Article from "./article/index";
import Pages from "./pages/index";
import Video from "./video/index";
import Photo from "./photo/index";
import Repertoire from "./repertoire/index";
import Feedback from "./feedback/index";
import Questions from "./questions/index";
import Journal from "./joural/index";

class Dashboard extends Component {
    components = {
        article: Article,
        pages:Pages,
        video:Video,
        photo:Photo,
        repertoire:Repertoire,
        feedback:Feedback,
        questions:Questions,
        journal: Journal
    };
    render() {
        const TagName = this.components[this.props.tag];
        return <TagName />
    }
}

Dashboard.propTypes = {};

export default Dashboard;
