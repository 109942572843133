import React, {Component} from 'react';
import {Button, Col, Row} from "reactstrap";
import axios from "axios";

import {ToastContainer, toast} from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css";
import {AvField, AvForm} from "availity-reactstrap-validation";
import ReactTable from "react-table";
import statick from "../../../../const/index";
import QuestionsForm from "./QuestionsForm";
import {Redirect} from "react-router-dom";

class Questions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            status: true,
            singleQuestion:'',
            statusToken:true
        }
    }

    componentDidMount() {
        try {
            axios.get(statick.serverUrl + "/api/question")
                .then(questions => {
                    this.setState({
                        questions: questions.data
                    });
                })
                .catch(e => {
                    console.log(e)
                })
        } catch (e) {
                console.log(e)
        }
    }
    setStatus=(id)=>{
        if(!id){
            if( !this.state.status ){
                try {
                    axios.get(statick.serverUrl + "/api/question")
                        .then(questions => {
                            this.setState({
                                questions: questions.data
                            });
                        })
                        .catch(e => {
                            console.log(e)
                        })
                } catch (e) {
                    console.log(e)
                }
            }
            this.setState({
                    status: !this.state.status,
                    singleQuestion:""
                });
        }else{
            try {
                axios.get(statick.serverUrl + "/api/question/"+id)
                    .then(questions => {
                        this.setState({
                            singleQuestion: questions.data,
                            status: !this.state.status
                        });
                    })
                    .catch(e => {
                        console.log(e)
                    })
            } catch (e) {
                console.log(e)
            }
        }
    };
    Delete=(id)=>{
        try{
            axios.delete(statick.serverUrl+"/api/question/"+this.state.model.id,{
                headers: {
                    Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                }
            })
                .then(question=>{
                    toast.success("Savol o'chirildi");
                }).catch(e=>{
                const status = e.response.status;
                if(status === 401){
                    toast.error("Iltimos tizimga qayta kiring!");
                    setTimeout(()=>{
                        localStorage.clear();
                        this.setState({
                            statusToken: false
                        })
                    },1000)
                }else{
                    toast.error("Rasm qo'shish bilan muamo bor!")
                }
            })

        }catch (e){
            console.log(e)
        }
    }
    //REACT TABLE SETTINGS
    columns = [{
        Header: 'Id',
        accessor: 'id' // String-based value accessors!
    }, {
        Header: 'Sarlavha',
        accessor: 'title',
    }, {
        Header: 'Qisqachamazmuni',
        accessor: 'description',
    }, {
        Header: "Amallar", // Custom header components!
        Cell: props => (<div>
            <Button color="outline-info" className="mr-2"
                    onClick={() => this.setStatus(props.original.id)}>Taxrirlash</Button>
            <Button color="outline-danger" onClick={() => this.Delete(props.original.id)}>&times;</Button>
        </div>)
    }];

    render() {
       if(this.state.statusToken){
           return (
               <div className="questions">
                   <Row>
                       <Col md="12" className="text-right">
                           <Button color="info" onClick={()=>this.setStatus()}>{
                               this.state.status?<span>Savol qo'shish</span>: <span>Ortga qaytish</span>
                           }</Button>
                       </Col>
                   </Row>
                   <Row>
                       {this.state.status ?
                           this.state.questions.length !== 0 ?
                               <Col md="12">
                                   <h4 className="m-0 py-4">Barcha savollar</h4>
                                   <ReactTable
                                       data={this.state.questions} defaultPageSize={10}
                                       previousText={'Oldingisi'}
                                       nextText={'Keyingisi'}
                                       loadingText={'Kuting...'}
                                       noDataText={'Repertuarlar mavjud emas!'}
                                       pageText={'Page'}
                                       ofText={'dan'}
                                       rowsText={'qator'}
                                       columns={this.columns}
                                   />
                               </Col>:
                               <Col md="12">
                                   <h1 className="m-0 py-5 text-center text-muted">
                                       Savollar mavjud emas!
                                   </h1>
                               </Col>
                           : <Col md="12"><QuestionsForm question={this.state.singleQuestion}/></Col>

                       }
                   </Row>
               </div>
           );
       }else{
           return (
               <Redirect to="/login"/>
           );
       }
    }
}

Questions.propTypes = {};

export default Questions;
