import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";
import PropTypes from 'prop-types';
import {Button, Col, Row} from "reactstrap";
import statick from "../../../../../const/index";
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Add from "./add";


class AllJournal extends Component {
    constructor(props){
        super(props);
        this.state = {
            allJournal: [],
            status: true,
            upStatus: false,
            model:""
        }
    }
    componentDidMount(){
        try{
            axios.get(statick.serverUrl+"/api/journal")
                .then(journals=>{
                    this.setState({
                        allJournal: journals.data,
                    })
                })
                .catch(e=>{
                    console.log(e);
                })
        }catch (e){
            console.log(e)
        }
    }
    setStatus=()=>{
        this.setState({
            status: !this.state.status
        })
    };
    Update=(id)=>{
        try{
            axios.get(statick.serverUrl+"/api/journal/"+id)
                .then(journals=>{
                    this.setState({
                        model: journals.data,
                        status: !this.state.status
                    })
                })
                .catch(e=>{
                    console.log(e);
                })
        }catch (e){
            console.log(e)
        }
    };
    Delete=(id)=>{
        try{
            axios.delete(statick.serverUrl+"/api/journal/"+id)
                .then(journals=>{
                    this.setState({
                        allJournal: journals.data
                    });
                    toast.success("O'chirildi!")
                })
                .catch(e=>{
                    console.log(e);
                })
        }catch (e){
            console.log(e)
        }
    };
    columns =  [{
        Header: 'Id',
        accessor: 'journal.id' // String-based value accessors!
    }, {
        Header: 'Sarlavha',
        accessor: 'journal.titleUz',
    },{
        Header: "Amallar", // Custom header components!
        Cell: props => (<div>
            <Button color="outline-info" className="mr-2" onClick={()=>this.Update(props.original.journal.id)}>Taxrirlash</Button>
            <Button color="outline-danger" onClick={()=>this.Delete(props.original.journal.id)}>&times;</Button>
        </div>)
    }];
    render() {
        return (
            <div>
                {
                    this.state.status ?
                        <Row>
                            <Col>
                                <h4 className="m-0 py-4">Barcha journallar</h4>
                                <ReactTable
                                    data={this.state.allJournal} defaultPageSize={10}
                                    previousText={'Oldingisi'}
                                    nextText={'Keyingisi'}
                                    loadingText={'Kuting...'}
                                    noDataText={'Repertuarlar mavjud emas!'}
                                    pageText={'Page'}
                                    ofText={'dan'}
                                    rowsText={'qator'}
                                    columns={this.columns}
                                />
                            </Col>
                        </Row> : <Add update={this.setStatus} model={this.state.model}/>
                }
            </div>
        );
    }
}

export default AllJournal;
