import React, {Component} from 'react';
import axios from "axios"
import statick from "../../const/index";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import Bar from "../../components/bar/index";
import Loader from "../../components/loader/loader";

class AllRepertories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            repertoire: [],
            loader:true,
            repertoires: false
        };
    }

    componentDidMount(){
        axios.get(statick.serverUrl+"/api/repertoire")
            .then(rep=>{
                this.setState({
                    repertoire : rep.data
                });
            }).catch(e=>{
            console.log(e);
        });
        const timeLoader = setTimeout(()=>{
            this.setState({
                loader:false
            })
        },2000)
    }
    render() {
        const statickLang = this.props.statickLang;
        const lang = this.props.lang;
        return (
            <div className="repertoire">
                {this.state.loader? <Loader/>:""}
                <Container className="bg-white">
                    <Row>
                        <Col md="9">
                            <Card className="border-0 rounded-0">
                                {this.state.repertoire.length !==0 ?
                                    this.state.repertoire.map((item,i)=>{
                                        if(item.lang === lang){
                                            return(
                                                <CardBody className="border-bottom border-light">
                                                    <Row>
                                                        <Col md="4">
                                                            <img src={statick.serverUrl+"/"+item.imageUrl} className="img-fluid" alt={item.title}/>
                                                        </Col>
                                                        <Col md="8">
                                                            <h4 className="font-weight-bold py-2">{item.title}</h4>
                                                            <p className="text-justify" contentEditable='false' dangerouslySetInnerHTML={{ __html:  item.description }}/>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            );
                                        }
                                    }):<h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>
                                }
                            </Card>
                        </Col>
                        <Col md="3">
                            <Bar lang={this.props.lang} statickLang={this.props.statickLang}/>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

AllRepertories.propTypes = {};

export default AllRepertories;
