import React, {Component} from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"
import {Card, CardBody, CardGroup, Col, Container, Row} from "reactstrap";
import {Link} from "react-scroll";
import statick from "../../const/index";
import axios from "axios";
import Loader from "../../components/loader/loader";
class AllGalery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gallery: [],
            galleryId: 1,
            images: [],
            title:'',
            description:'',
            loader:true
        }
    }
    changeGallery=(id)=>{
        this.setState({
            galleryId: id
        });
    };
    componentDidMount(){
        axios.get(statick.serverUrl+"/api/gallery/all")
            .then(gallery=>{
                this.setState({
                    gallery : gallery.data
                });
            }).catch(e=>{
            console.log(e);
        })
        const timeLoader = setTimeout(()=>{
            this.setState({
                loader:false
            })
        },2000)
    }
    render() {
        let images = [];
        let title;
        let description;
        let gallery=[];
        const lang=this.props.lang;
        const statickLang = this.props.statickLang;
        if(this.state.gallery.length!==0){
            this.state.gallery.forEach(item=>{
                if(item.gallery.lang==lang){
                    if(item.gallery.id === this.state.galleryId){
                        images = item.images;
                        title = item.gallery.title;
                        description = item.gallery.description;
                    }
                }
            });
            images.forEach(item=>{
                gallery.push({
                    original: statick.serverUrl+"/"+item.imageUrl,
                    thumbnail:  statick.serverUrl+"/"+item.imageUrl,
                })
            });
        }
        return (
            <div className="gallery-page" id="div">
                {this.state.loader? <Loader/>:""}
               <Container className="bg-white py-4 px-5">
                   {
                       this.state.gallery.length !== 0 ?
                           <div>
                               <Row>
                                   <Col md="12">
                                       <h4 className="text-muted m-0 py-2">{statickLang.PHOTO}</h4>
                                       {
                                           gallery.length!==0 ?
                                               <div>
                                                   <Card className="border-left-0 border-right-0 border-light rounded-0 my-4">
                                                       <h4 className="py-4 mb-0">{title}</h4>
                                                   </Card>
                                                   <ImageGallery items={gallery} thumbnailPosition={"left"}/>
                                               </div>
                                               : <h1 className="text-muted text-center py-5">{statickLang.NOCONTENT}</h1>
                                       }
                                       <p className="py-4 mb-0">{description}</p>
                                   </Col>
                               </Row>
                               <h4 className="text-muted m-0 py-4">{statickLang.OTHERPHOTO}</h4>
                               <Row className="my-4">
                                   {
                                       this.state.gallery.map((item,i)=>{
                                           if(item.gallery.lang ==lang ){
                                               return(
                                                   <Col md="3" key={i}>
                                                       <Link onClick={()=>this.changeGallery(item.gallery.id)} to="div"
                                                             spy={true}
                                                             smooth={true}
                                                             hashSpy={true}
                                                             offset={50}
                                                             duration={500}
                                                             delay={100}
                                                             isDynamic={true}>
                                                           <div className="gallery-carousel bg-light" onClick={()=>this.changeGallery(item.gallery.id)}>
                                                               <img className="img-fluid" src={statick.serverUrl+"/"+item.images[0].imageUrl} alt={item.gallery.title}/>
                                                               <CardBody>
                                                                   <h6 className="p-0 m-0">
                                                                       {item.gallery.title}
                                                                   </h6>
                                                               </CardBody>
                                                           </div>
                                                       </Link>

                                                   </Col>
                                               );
                                               this.setState({
                                                   language: true
                                               })
                                           }
                                       })
                                   }
                               </Row>
                           </div> :
                           <div className="jumbotron bg-transparent">
                               <h1 className="text-center text-muted">{statickLang.NOCONTENT}</h1>
                           </div>
                   }
               </Container>
            </div>
        );
    }
}

AllGalery.propTypes = {};

export default AllGalery;
