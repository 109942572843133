import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactTable from "react-table";
import "react-table/react-table.css"
import statick from "../../../../../const/index";
import {Button, Col, Row} from "reactstrap";
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AddDescription from "./addDesc";

class AllDescription extends Component {
    constructor(props) {
        super(props);
        this.state={
            status: true,
            allDesc:[],
            updateItem:'',
            setStatus: this.props.setStatus
        }
    }
    componentDidMount(){
        try{
            axios.get(statick.serverUrl+"/api/journalDesc")
                .then(desc=>{
                    this.setState({
                        allDesc: desc.data
                    })
                })
                .catch(e=>{
                    console.log(e);
                })
        }catch (e){
            console.log(e)
        }
    }
    Update=(id)=>{
        try{
            axios.get(statick.serverUrl+"/api/journalDesc/"+id)
                .then(desc=>{
                    this.setState({
                        updateItem: desc.data,
                        status:false
                    });
                })
                .catch(e=>{
                    console.log(e);
                })

        }catch (e){
            console.log(e)
        }
    };
    Delete=(id)=>{
        try{
            axios.delete(statick.serverUrl+"/api/journalDesc/"+id)
                .then(desc=>{
                   if( desc.data.message) {
                       toast.success("Ma'lumot o'chirildi");
                       axios.get(statick.serverUrl+"/api/journalDesc")
                           .then(desc=>{
                               this.setState({
                                   allDesc: desc.data
                               })
                           })
                           .catch(e=>{
                               console.log(e);
                           })
                    }else{
                       toast.error("Ma'lumot o'chirish bilan muamo bor!")
                   }
                })
                .catch(e=>{
                    console.log(e);
                });
        }catch (e){
            console.log(e)
        }
    };
    setStatus=()=>{
        this.setState({
            status:true
        });
    }
    columns =  [{
        Header: 'Id',
        accessor: 'description.id' // String-based value accessors!
    }, {
        Header: 'Journal nomi',
        accessor: 'parent.titleUz',
    },{
        Header: "Amallar", // Custom header components!
        Cell: props => (<div>
            <Button color="outline-info" className="mr-2" onClick={()=>this.Update(props.original.description.id)}>Taxrirlash</Button>
            <Button color="outline-danger" onClick={()=>this.Delete(props.original.description.id)}>&times;</Button>
        </div>)
    }];
    render() {
        return (
            <div>
                <ToastContainer/>
                {
                    this.state.status ?
                        <Row>
                            <Col>
                                <h4 className="m-0 py-4">Barcha journallar</h4>
                                <ReactTable
                                    data={this.state.allDesc} defaultPageSize={10}
                                    previousText={'Oldingisi'}
                                    nextText={'Keyingisi'}
                                    loadingText={'Kuting...'}
                                    noDataText={'Repertuarlar mavjud emas!'}
                                    pageText={'Page'}
                                    ofText={'dan'}
                                    rowsText={'qator'}
                                    columns={this.columns}
                                />
                            </Col>
                        </Row> : <AddDescription update={this.state.status} setStatus={this.setStatus} updateItem={this.state.updateItem}/>
                }
            </div>
        );
    }
}

AllDescription.propTypes = {};

export default AllDescription;
