import React, {Component} from 'react';
import PropTypes from 'prop-types';
import statick from "../const/index";
import axios from "axios";
import {Col, Container, Row} from "reactstrap";
import Bar from "../components/bar/index";
import {Link} from "react-router-dom";
import Loader from "../components/loader/loader";

class AllQuestions extends Component {
    constructor(props){
        super(props);
        this.state={
            questions: [],
            loader:true
        }
    }
    componentDidMount(){
        try{
            axios.get(statick.serverUrl+"/api/question")
                .then(question=>{
                    this.setState({
                        questions: question.data
                    })
                }).catch(e=>{
                console.log(e)
            })

        }catch (e){
            console.log(e)
        }
        const timeLoader = setTimeout(()=>{
            this.setState({
                loader:false
            })
        },2000)
    }
    render() {
        const statickLang = this.props.statickLang;
        const lang = this.props.lang;
        return (
            <div className="single-article">
                {this.state.loader? <Loader/>:""}
                <Container className="bg-white py-4">
                    <Row>
                        <Col md="9">
                            <div className="questions py-4 px-5">
                                <p className="text-muted">{statickLang.QUESTION.TITLE}</p>
                                {
                                    this.state.questions.length !==0 ?
                                        this.state.questions.map((item,i)=>{
                                            if(item.leng === lang){
                                                return(
                                                    <div className="border-bottom border-light py-3">
                                                        <p className="text-color f-bold">
                                                            {item.title}
                                                        </p>
                                                        <p className="text-muted">
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        })
                                        :<h2 className="text-center text-muted py-5 my-5">{statickLang.NOCONTENT}</h2>
                                }
                            </div>
                            <Row>
                                <Col md="12" className="text-right py-4">
                                    <Link to="/" className="text-decoration-none">{statickLang.TOHOME}</Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="3">
                            <Bar lang={this.props.lang} statickLang={this.props.statickLang}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

AllQuestions.propTypes = {};

export default AllQuestions;
