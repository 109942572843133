import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from "axios"
import statick from "../../const/index";
import {ToastContainer,toast} from "react-toastify";
import {Col, Container, Row} from "reactstrap";
import Bar from "../../components/bar/index";

class Pages extends Component {
    constructor(props){
        super(props);
        this.state={
            page: "",
            desc:"",
            id: this.props.location.state.pageId,
            status:false
        }
    }
    componentDidMount() {
        try {
            axios.get(statick.serverUrl+"/api/page/"+this.state.id)
                .then(page=>{
                    this.setState({
                        pages:page.data
                    });
                }).catch(e=>{
                console.log(e);
            });
            axios.get(statick.serverUrl+"/api/descriptions/"+this.state.id)
                .then(desc=>{
                    this.setState({
                        desc:desc.data
                    });
                }).catch(e=>{
                console.log(e);
            });

        }catch (e){
            toast.error("Ma'lumot yuklash bilan bog'liq hatolik bor!",{
                position: toast.POSITION.TOP_CENTER
            })
        }
}
    render() {
        return (
            <div>
                <ToastContainer/>
                <Container className="py-5 bg-white">
                    <Row>
                        <Col md="9">
                            {
                                this.props.location.state.pageId ?
                                    <div>
                                        <h4 className="m-0 pb-4">{
                                            this.state.pages ? this.state.pages.titleUz :""
                                        }</h4>
                                        <div className="text-justify" contentEditable='false' dangerouslySetInnerHTML={{ __html: this.state.desc }}/>
                                    </div>:""
                            }
                        </Col>
                        <Col md="3">
                            <Bar/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Pages.propTypes = {};

export default Pages;
