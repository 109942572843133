import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Col, Container, Row} from "reactstrap";

class NotFounded extends Component {
    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col md="12" className="text-center">
                            <h1 className="font-weight-bold m-0 py-5">
                                404
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

NotFounded.propTypes = {};

export default NotFounded;
