import React, {Component} from 'react';
import axios from "axios"
import statick from "../../const/index";
import {ToastContainer,toast} from "react-toastify";
import {Col, Container, Row} from "reactstrap";
import Bar from "../../components/bar/index"
import Loader from "../../components/loader/loader";

class Anons extends Component {
    constructor(props){
        super(props);
        this.state = {
            pages: "",
            desc:"",
            lang: this.props.lang
        }
    }
    componentDidMount() {
        try {
            axios.get(statick.serverUrl+"/api/page/"+6)
                .then(page=>{
                    this.setState({
                        pages:page.data
                    });
                }).catch(e=>{
                console.log(e);
            });
            axios.get(statick.serverUrl+"/api/descriptions/"+6)
                .then(desc=>{

                    if(this.state.lang === "uz"){
                        this.setState({
                            desc:desc.data.descriptionUz
                        });
                    }else if(this.state.lang === "ru"){
                        this.setState({
                            desc:desc.data.descriptionRu
                        });
                    }else if(this.state.lang === "eng"){
                        this.setState({
                            desc:desc.data.descriptionEng
                        });
                    }
                }).catch(e=>{
                console.log(e);
            });

        }catch (e){
            toast.error("Ma'lumot yuklash bilan bog'liq hatolik bor!",{
                position: toast.POSITION.TOP_CENTER
            })
        }
    }
    render() {
        return (
            <div className="anons">
                <div className="text-justify" contentEditable='false' dangerouslySetInnerHTML={{ __html: this.state.desc }}/>
            </div>
        );
    }
}

Anons.propTypes = {};

export default Anons;
