import React, {Component} from 'react';

import {Card, CardBody, Col, Row,Button} from "reactstrap";
import VideoList from "./videoList";
import AddVideo from "./addVideo";


class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {
           allVideo:[],
           galleryStatus: false
        }
    }

    setGallery =()=>{
        this.setState({
            galleryStatus:!this.state.galleryStatus
        })
    }
    render() {
        return (
            <div className="dsh-video-block">
                <Row>
                    <Col md="6">
                        <h4 className="m-0 py-4">Video galereyalar</h4>
                    </Col>
                    <Col md="6" className="text-right py-4">
                        <Button color="info" className="small" onClick={this.setGallery}>{this.state.galleryStatus ?<span>Barcha videolar</span>:<span>Video qo'shish</span>}</Button>
                    </Col>
                </Row>
                {this.state.galleryStatus?<AddVideo status={this.setGallery}/>:<VideoList/>}
            </div>
        );
    }
}

Video.propTypes = {};

export default Video;
