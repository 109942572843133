import React, {Component} from 'react';
import axios from "axios";
import {
    Button,
    Col,
    Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon, Nav, Navbar,
    NavbarToggler, NavItem,
    NavLink, Row,
    UncontrolledDropdown
} from "reactstrap";
import './index.scss'
import {Link} from "react-router-dom";
import statick from "../../const/index";
import {ToastContainer,toast} from "react-toastify";



const  menu = (item,lang)=>{
    if(lang ==="uz"){
        return (<span>{item.titleUz}</span>)
    }else if(lang ==="ru"){
        return (<span>{item.titleRu}</span>)
    }else{
        return (<span>{item.titleEng}</span>)
    }

};
class Header extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            searchText: "",
            isOpen: false,
            pages:[],
            lang:this.props.lang,
            statickLang: this.props.statikLang,
        };
    }
    componentDidMount() {
        try {
            axios.get(statick.serverUrl+"/api/page")
                .then(page=>{
                    this.setState({
                        pages:page.data
                    });
                }).catch(e=>{
                console.log(e);
            });
        }catch (e){
            toast.error("Ma'lumot yuklash bilan bog'liq hatolik bor!",{
                position: toast.POSITION.TOP_CENTER
            })
        }
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    setSearchText=(e)=>{
        this.setState({
            searchText: e.target.value
        })
    }
    render() {
        return (
            <header>
                <ToastContainer/>
                <Container>
                    <Navbar color="transparent" className="position-relative" dark expand="md">
                        <Link to="/" className="navbar-brand">
                            <img src="/src/images/logo.png" className="img-fluid wow fadeInLeft d-inline-block d-md-none" data-wow-duration="2s" data-wow-delay="0.5s" alt="LOGO" />
                            <p className="m-0 text-white small pl-3 d-block d-md-none">
                                {
                                    this.props.lang === "uz" ?
                                        <span>
                                                {this.state.statickLang.uz.SIRK}
                                            </span>:
                                        <span>
                                                {
                                                    this.props.lang ==="ru" ? this.state.statickLang.ru.SIRK : this.state.statickLang.eng.SIRK
                                                }
                                            </span>
                                }
                            </p>
                        </Link>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav navbar>
                                <NavItem className="mx-2">
                                    <Link className="nav-link" to="/">
                                        <span className="icon icon-home" />
                                    </Link>
                                </NavItem>
                                {
                                    this.state.pages.length !==0 ?
                                        this.state.pages.map((item,i)=>{
                                            if(i<5){
                                                return(
                                                    <NavItem key={i} className="mx-2">
                                                        <Link className="nav-link text-white" to={{ pathname : "/"+item.name, state: { pageId : item.id } }}>
                                                            {
                                                                menu(item,this.props.lang)
                                                            }
                                                        </Link>
                                                    </NavItem>
                                                )
                                            }
                                        }): ""
                                }
                            </Nav>
                            <Nav className="ml-auto" navbar>
                                <NavItem>
                                    <Button onClick={this.props.eye} color="link" className="nav-link text-white">
                                        <span className="icon icon-eye" />
                                    </Button>
                                </NavItem>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle className="text-white text-capitalize" nav caret>
                                        {this.props.lang}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={()=>this.props.setlang("uz")}>
                                            Uz
                                        </DropdownItem>
                                        <DropdownItem onClick={()=>this.props.setlang("ru")}>
                                            Ru
                                        </DropdownItem>
                                        <DropdownItem onClick={()=>this.props.setlang("eng")}>
                                            Eng
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        </Collapse>
                    </Navbar>
                    <Row className="search-block py-5 my-3">
                        <Col md="4" className="text-center">
                            <div className="beta">
                                <span>Beta</span>
                            </div>
                            <Link to="/" className="text-decoration-none">
                                <img src="src/images/logo.png" className="d-none d-md-inline-block img-fluid wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.5s" alt="LOGO" />
                                <p className="m-0 text-white small d-none d-md-block pl-3">
                                    {
                                        this.props.lang === "uz" ?
                                            <span>
                                                {this.state.statickLang.uz.SIRK}
                                            </span>:
                                            <span>
                                                {
                                                    this.props.lang ==="ru" ? this.state.statickLang.ru.SIRK : this.state.statickLang.eng.SIRK
                                                }
                                            </span>
                                    }
                                </p>
                            </Link>
                        </Col>
                        <Col md={{offset:1,size:7}}>
                            <form action="#" method="GET">
                                <InputGroup className="mt-4">
                                    <Input onKeyUp={this.props.setSearchText} placeholder={
                                        this.props.lang === "uz" ?
                                            this.state.statickLang.uz.SEARCH :
                                            this.props.lang ==="ru" ? this.state.statickLang.ru.SEARCH : this.state.statickLang.eng.SEARCH
                                    } className="pl-3"/>
                                    <InputGroupAddon addonType="prepend">
                                        {
                                            this.props.status ?
                                                <Button color="primary" onClick={this.props.setStatus}><span className="icon icon-search" /></Button>:
                                                <Link to="/search"  className="btn btn-primary"><span className="icon icon-search" /></Link>
                                        }

                                    </InputGroupAddon>
                                </InputGroup>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </header>
        );
    }
}

Header.propTypes = {};

export default Header;
