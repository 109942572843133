import React, {Component} from 'react';
import axios from "axios";
import {Button, Col, Container, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import statick from "../../const/index";
import {Redirect} from "react-router-dom";
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css"
import Loader from "../../components/loader/loader";

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state={
            login: false,
            loader:true
        }
    }
    componentDidMount(){
        const timeLoader = setTimeout(()=>{
            this.setState({
                loader:false
            })
        },2000)
    }
    handleChange(event,error,value){
        try {
            axios.post(statick.serverUrl+"/api/login",value)
                .then(token=>{
                    if(!token.data.message){
                        toast.error("Login yoki parol notog'ri")
                    }else{
                        localStorage.setItem("token",token.data.token);
                        this.setState({
                            login: true
                        })
                    }
                }).catch(e=>{
                    console.log(e);
            })
        }catch (e){
            console.log(e)
        }
    }
    render() {
        if(!this.state.login){
            return (
                <div className="login-page">
                    {this.state.loader? <Loader/>:""}
                    <ToastContainer/>
                    <Container className="bg-white p-5">
                        <h2 className="text-center py-5">Sirk.uz</h2>
                        <Row>
                            <Col md={{size: 6, offset:3}}>
                                <AvForm onSubmit={this.handleChange}>
                                    <AvField type="text" name="login" required label="Logini kiriting"/>
                                    <AvField type="password" name="password" required label="Parolni kiriting"/>
                                    <Button className="float-right" color="primary">Kirish</Button>
                                </AvForm>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }else{
            return(
                <Redirect to="/dashboard"/>
            )
        }

    }
}

Login.propTypes = {};

export default Login;
