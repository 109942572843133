import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import statick from "../../../../const/index";
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Redirect} from "react-router-dom";

class AllGalery extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.state = {
            token: localStorage.getItem('token'),
            statusToken: true,
            allGallery:[],
            gallery: this.props.gallery,
            oneGallery: ''
        }
    }
    handleSubmit(event, errors, values) {
        if(values.description && values.title && values.lang){
            try{
                axios.post(statick.serverUrl+"/api/gallery",values,{
                    headers: {
                        Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                    }
                })
                    .then(gallery=>{
                        this.setState({
                            allGallery:gallery.data,
                            gallery:!this.state.gallery
                        });
                        toast.success("Galereya yaratildi!");
                    }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Galereya yaratilmadi!");
                    }
                });
            }catch (e){
                toast.error("Rasm yuklash bilan hatolik yuz berdi!")
            }
        }else{
            toast.error("Ma'lumotlarni to'liq to'ldiring!")
        }
    }
    handleUpdate(event, errors, values) {
        const id = this.state.oneGallery.id;
        if(values.description && values.title && values.lang){
            try{
                axios.put(statick.serverUrl+"/api/gallery/"+id,values,{
                    headers: {
                        Authorization: this.state.token //the token is a variable which holds the token
                    }
                })
                    .then(gallery=>{
                        this.setState({
                            allGallery:gallery.data,
                            gallery:!this.state.gallery,
                            oneGallery:''
                        });
                        toast.success("Galereya o'zgartirildi!");
                    }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Galereya yaratilmadi!");
                    }
                });
            }catch (e){
                toast.error("Rasm yuklash bilan hatolik yuz berdi!")
            }
        }
    }
    componentDidMount() {
        try{
            axios.get(statick.serverUrl+"/api/gallery")
                .then(gallery=>{
                    this.setState({
                        allGallery:gallery.data,
                    })
                }).catch(e=>{
                console.log(e);
            });
        }catch (e){
            toast.error("Rasm yuklash bilan hatolik yuz berdi!")
        }
    }
    deletedGallery =(id)=>{
        try {
            axios.delete(statick.serverUrl+"/api/gallery/"+id,{
                headers: {
                    Authorization: this.state.token //the token is a variable which holds the token
                }
            })
                .then(gallery=>{
                    this.setState({
                        allGallery:gallery.data,
                    })
                }).catch(e=>{
                const status = e.response.status;
                if(status === 401){
                    toast.error("Iltimos tizimga qayta kiring!");
                    setTimeout(()=>{
                        localStorage.clear();
                        this.setState({
                            statusToken: false
                        })
                    },1000)
                }else{
                    toast.error("Galereya yaratilmadi!");
                }
            });

        }catch (e){
            console.log(e)
        }
    };
    updateGallery=(id)=>{
        try {
            axios.get(statick.serverUrl+"/api/gallery/"+id)
                .then(gallery=>{
                    this.setState({
                        oneGallery:gallery.data,
                        gallery:!this.state.gallery
                    })
                }).catch(e=>{
                console.log(e);
            });

        }catch (e){
            console.log(e)
        }
    };
    addGallery =()=>{
        this.setState({
            gallery:!this.state.gallery
        })
    };
    render() {
        if(this.state.statusToken ){
            return (
                <div className="dsh-all-agllery">
                    <ToastContainer/>
                    {
                        this.state.gallery ?
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            {   this.state.allGallery.length===0?
                                                <h4 className="text-center text-muted mb-0">Foto galereya bo'sh!</h4>:
                                                <table className="table table-bordered">
                                                    <thead>
                                                    <tr>
                                                        <th>T/R</th>
                                                        <th>Sarlavxa</th>
                                                        <th>Mazmuni</th>
                                                        <th>Qo'shimcha</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.allGallery.map((item,i)=>{
                                                        return(
                                                            <tr key={item.id} className="small">
                                                                <td>{i+1}</td>
                                                                <td>{item.title}</td>
                                                                <td>{item.description}</td>
                                                                <td>
                                                                    <Button color="outline-info" className="small py-1 px-2 mr-2" onClick={()=>this.updateGallery(item.id)}>Taxrirlash</Button>
                                                                    <Button color="outline-danger" className="small py-1 px-2" onClick={()=>this.deletedGallery(item.id)}>&times;</Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>

                                                </table>
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardBody className="text-right">
                                    <Button color="info" className="py-2 px-3" onClick={this.addGallery}>Galereya yaratish</Button>
                                </CardBody>
                            </Card>
                            :
                            <Card>
                                <CardBody>
                                    {this.state.oneGallery ?
                                        //UPDATE GALERY
                                        <AvForm onSubmit={this.handleUpdate} model={this.state.oneGallery}>
                                            <Row>
                                                <Col md="6">
                                                    <AvField type="text" name="title" label="Sarlavha" required />
                                                </Col>
                                                <Col md="6">
                                                    <AvField type="select" name="lang" label="Tilni tanlang" required>
                                                        <option value="0" disabled>Tilni tanlang</option>
                                                        <option value="uz">UZ</option>
                                                        <option value="eng">ENG</option>
                                                        <option value="ru">RU</option>
                                                    </AvField>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <AvField type="textarea" name="description" label="Asosiy matin" required />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12" className="text-right">
                                                    <Button color="outline-danger" className="py-2 px-3 mr-2 small" onClick={this.addGallery}>Bekor qilish</Button>
                                                    <Button color="primary" className="py-2 px-3 small">Saqlash</Button>
                                                </Col>
                                            </Row>
                                        </AvForm>:
                                        //CREATE GALERY
                                        <AvForm onSubmit={this.handleSubmit}>
                                            <Row>
                                                <Col md="6">
                                                    <AvField type="text" name="title" label="Sarlavha" required />
                                                </Col>
                                                <Col md="6">
                                                    <AvField type="select" name="lang" label="Tilni tanlang" required>
                                                        <option value="0" disabled>Tilni tanlang</option>
                                                        <option value="uz">UZ</option>
                                                        <option value="eng">ENG</option>
                                                        <option value="ru">RU</option>
                                                    </AvField>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <AvField type="textarea" name="description" label="Asosiy matin" required />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12" className="text-right">
                                                    <Button color="outline-danger" className="py-2 px-3 mr-2 small" onClick={this.addGallery}>Bekor qilish</Button>
                                                    <Button color="primary" className="py-2 px-3 small">Saqlash</Button>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    }
                                </CardBody>
                            </Card>
                    }
                </div>
            );
        }else{
            return(
                <Redirect to="/login"/>
            )
        }

    }
}

AllGalery.propTypes = {};

export default AllGalery;
