import React, {Component} from 'react';
import axios from "axios";
import statick from "../../const/index";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import Bar from "../../components/bar/index";
import {Link} from "react-router-dom";
import Loader from "../../components/loader/loader";

class AllArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleData: [],
            lang: this.props.lang,
            statickLang: this.props.statickLang,
            loader:true
        };
    }
    componentDidMount() {
        axios.get(statick.serverUrl+"/api/article")
            .then(article=>{
                this.setState({
                    articleData : article.data,
                    articleNum: 3,

                });
            }).catch(e=>{
            console.log(e);
        });
        const timeLoader = setTimeout(()=>{
            this.setState({
                loader:false
            })
        },2000)
    }
    setArticleNum=()=>{
        let articleNum = this.state.articleNum;
        articleNum++;
        this.setState({
            articleNum:articleNum
        })
    }

    render() {
        const articles = this.state.articleData;
        const statickLang = this.props.statickLang;
        const lang = this.props.lang;
        let num = 0;
        return (
            <div>
                {this.state.loader? <Loader/>:""}
                <Container className="bg-white py-4">
                    <Row>
                        <Col md="9">
                            <h6 className="text-muted mb-0 px-4">{statickLang.ALLNEWS}</h6>
                            <div className="article-block px-4">
                                {
                                    this.state.articleData.length !=0 ?
                                        <div>
                                            {
                                                articles.map((item, i) => {
                                                    if(item.lang===this.props.lang){
                                                        if(this.state.articleNum > num){
                                                            num++;
                                                            return(
                                                                <Card key={i} className="border-0">
                                                                    <CardBody className="border-bottom border-light">
                                                                        <Row>
                                                                            <Col md="4">
                                                                                <img src={statick.serverUrl+"/api/images/"+item.imagesUrl} className="img-fluid" alt={item.title}/>
                                                                            </Col>
                                                                            <Col md="8">
                                                                                <h4>
                                                                                    <Link className="text-decoration-none" to={`/article/${item.id}`}>{item.title}</Link>
                                                                                </h4>
                                                                                <p>{item.description}</p>
                                                                                <p className="text-right text-muted small">{
                                                                                    item.updatedAt.substring(8,10)+
                                                                                    "."+item.updatedAt.substring(5,7)+
                                                                                    "."+item.updatedAt.substring(0,4)
                                                                                }</p>
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                            {
                                                num === 0 ? <h1 className="text-center text-muted py-5">{this.props.statickLang.NOCONTENT}</h1> :
                                                <div className="py-4 text-right">
                                                    <Button color="primary" onClick={this.setArticleNum}>{this.props.statickLang.AGAIN}</Button>
                                                </div>
                                            }
                                        </div>

                                        : <h1 className="text-center text-muted py-5">{this.props.statickLang.NOCONTENT}</h1>
                                }
                            </div>
                        </Col>
                        <Col md="3">
                            <Bar lang={this.props.lang} statickLang={this.props.statickLang}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

AllArticle.propTypes = {};

export default AllArticle;
