import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import {Button, Card, CardBody, Col, Row} from "reactstrap";
import statick from "../../../../const/index";
import ReactTable from "react-table";
import "react-table/react-table.css"
import {AvField, AvForm} from "availity-reactstrap-validation";

//COMPONENT TEXT REDACTOR
import { Editor } from '@tinymce/tinymce-react'

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css'
import {Redirect} from "react-router-dom";
class Pages extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            token: localStorage.getItem('token'),
            statusToken: true,
            pages:[],
            page:[],
            status:true,
            uzdescription:'',
            rudescription:'',
            engdescription:'',
        };
    }
    componentDidMount(){
        try{
            axios.get(statick.serverUrl+"/api/page")
                .then(pages=>{
                    this.setState({
                        pages: pages.data,
                    })
                }).catch(e=>{
                console.log(e);
            });
        }catch (e){
            toast.error("Rasm yuklash bilan hatolik yuz berdi!")
        }
    }
    Update=(id)=>{
        try{
            axios.get(statick.serverUrl+"/api/page/"+id)
                .then(page=>{
                    this.setState({
                        page: page.data,
                        status:false,
                    })
                }).catch(e=>{
                console.log(e);
            });
            axios.get(statick.serverUrl+"/api/descriptions/"+id)
                .then(page=>{
                    this.setState({
                        uzdescription:page.data.descriptionUz,
                        rudescription:page.data.descriptionRu,
                        engdescription:page.data.descriptionEng,
                        status:false,
                    })
                }).catch(e=>{
                console.log(e);
            });
        }catch (e){
            toast.error("Rasm yuklash bilan hatolik yuz berdi!")
        }
    };
    handleSubmit(event,error,value){
        const id = this.state.page.id;
        if(error.length===0){
            try{
                axios.put(statick.serverUrl+"/api/page/"+id, value,{
                    headers: {
                        Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                    }
                })
                    .then(pages=>{
                        this.setState({
                            pages: pages.data,
                            status:true
                        });
                    }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }
                });
                axios.put(statick.serverUrl+"/api/descriptions/"+id, value,{
                    headers: {
                        Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                    }
                })
                    .then(pages=>{
                        this.setState({
                            status:true
                        });
                    }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }
                });
                toast.success("O'zgartirildi!")
            }catch (e){
                toast.error("Rasm yuklash bilan hatolik yuz berdi!")
            }
        }

    }

    handleChange(value){
        const id = value.target.id;
        switch(id){
            case "uz":
                this.setState({
                    uzdescription:value.level.content
                });
                break;
            case "ru":
                this.setState({
                    rudescription:value.level.content
                });
                break;
            case "eng":
                this.setState({
                    engdescription:value.level.content
                });
                break;
        }
    }
    changePhoto=(e)=>{
        this.setState({
            images: e.target.files[0]
        })
    };
    columns =  [{
        Header: 'Id',
        accessor: 'id' // String-based value accessors!
    }, {
        Header: 'Sarlavha',
        Cell: props => (<div>
            <span className="d-inline-block py-2 font-weight-bold px-3 mr-2">{props.original.titleUz}</span>
            <span className="d-inline-block small py-2 px-3 mr-2">{props.original.titleRu}</span>
            <span className="d-inline-block small py-2 px-3 mr-2">{props.original.titleEng}</span>

        </div>)
    },{
        Header: "Amallar", // Custom header components!
        Cell: props => (<div className="text-center">
            <Button color="outline-info" className="mr-2" onClick={()=>this.Update(props.original.id)}>Taxrirlash</Button>
        </div>)
    }];
    render() {
        if(this.state.statusToken){
            return (
                <div>
                    <ToastContainer/>
                    {
                        this.state.status ?
                            <Card>
                                <CardBody>
                                    {
                                        this.state.pages.length!==0 ?
                                            <ReactTable
                                                data={this.state.pages} defaultPageSize={5}
                                                previousText={'Oldingisi'}
                                                nextText={'Keyingisi'}
                                                loadingText={'Kuting...'}
                                                noDataText={'Sahifalar mavjud emas!'}
                                                pageText={'Page'}
                                                ofText={'dan'}
                                                rowsText={'qator'}
                                                columns={this.columns}
                                            />:""
                                    }
                                </CardBody>
                            </Card>    :

                            <Card>
                                <CardBody>
                                    <AvForm onSubmit={this.handleSubmit} model={this.state.page}>
                                        <Row>
                                            <Col md="4">
                                                <AvField name="titleUz" label="Sarlavha Uz" required/>
                                            </Col>
                                            <Col md="4">
                                                <AvField name="titleRu" label="Sarlavha Ru" required/>
                                            </Col>
                                            <Col md="4">
                                                <AvField name="titleEng" label="Sarlavha Eng" required/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <Accordion className="border-0">
                                                    <AccordionItem expanded={true} className="my-4 border border-light">
                                                        <AccordionItemTitle>
                                                            Asosiy matin o'zbek tilida
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <AvField name="descriptionUz" type="textarea" hidden require value={this.state.uzdescription}/>
                                                            <Editor id="uz"
                                                                    initialValue={this.state.uzdescription}
                                                                    apiKey="8vznrqbyk30khp4r9jiuzg54xctka8k2p2m5357xkksjieja"
                                                                    init={{
                                                                        plugins: [
                                                                            "advlist autolink lists link image charmap print preview",
                                                                            "searchreplace visualblocks code fullscreen",
                                                                            "insertdatetime table paste imagetools wordcount"
                                                                        ],
                                                                        toolbar: "customInsertButton | insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link",
                                                                        file_picker_types: 'image',
                                                                        extended_valid_elements : "iframe[src|frameborder|style|scrolling|class|width|height|name|align]",
                                                                        file_picker_callback: function (cb, value, meta) {

                                                                            let input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');
                                                                            input.onchange = function () {
                                                                                const formData = new FormData();
                                                                                const imagefile = this.files[0];
                                                                                formData.append("images", imagefile);
                                                                                try {
                                                                                    axios.post(statick.serverUrl+"/api/attachment",formData,{
                                                                                        headers: {
                                                                                            'Content-Type': 'multipart/form-data',
                                                                                            Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                                                                                        }
                                                                                    }).then(images=>{
                                                                                        const imageUrl = images.data[0].imageUrl;
                                                                                        cb(statick.serverUrl+"/"+imageUrl,{title: "Salom!"})
                                                                                    }).catch(e=>{
                                                                                        console.log(e);
                                                                                        toast.error("Rasm qo'shish bilan muamo bor!")
                                                                                    });
                                                                                }catch (e){
                                                                                    console.log(e)
                                                                                }
                                                                            };
                                                                            input.click();
                                                                        }
                                                                    }}
                                                                    onChange={this.handleChange}/>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                    <AccordionItem className="my-4 border border-light">
                                                        <AccordionItemTitle>
                                                            Asosiy matin rus tilida
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <AvField name="descriptionRu" type="textarea" hidden require value={this.state.rudescription}/>
                                                            <Editor id="ru"
                                                                    initialValue={this.state.rudescription}
                                                                    apiKey="8vznrqbyk30khp4r9jiuzg54xctka8k2p2m5357xkksjieja"
                                                                    init={{
                                                                        plugins: [
                                                                            "advlist autolink lists link image charmap print preview",
                                                                            "searchreplace visualblocks code fullscreen",
                                                                            "insertdatetime table paste imagetools wordcount"
                                                                        ],
                                                                        toolbar: "customInsertButton | insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link",
                                                                        file_picker_types: 'image',
                                                                        extended_valid_elements : "iframe[src|frameborder|style|scrolling|class|width|height|name|align]",
                                                                        file_picker_callback: function (cb, value, meta) {

                                                                            let input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');
                                                                            input.onchange = function () {
                                                                                const formData = new FormData();
                                                                                const imagefile = this.files[0];
                                                                                formData.append("images", imagefile);
                                                                                try {
                                                                                    axios.post(statick.serverUrl+"/api/attachment",formData,{
                                                                                        headers: {
                                                                                            'Content-Type': 'multipart/form-data',
                                                                                            Authorization: localStorage.getItem('token')//the token is a variable which holds the token
                                                                                        }
                                                                                    }).then(images=>{
                                                                                        const imageUrl = images.data[0].imageUrl;
                                                                                        cb(statick.serverUrl+"/"+imageUrl,{title: "Salom!"})
                                                                                    }).catch(e=>{
                                                                                        console.log(e);
                                                                                        toast.error("Rasm qo'shish bilan muamo bor!")
                                                                                    });
                                                                                }catch (e){
                                                                                    console.log(e)
                                                                                }
                                                                            };
                                                                            input.click();
                                                                        }
                                                                    }}
                                                                    onChange={this.handleChange}/>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                    <AccordionItem className="my-4 border border-light">
                                                        <AccordionItemTitle>
                                                            Asosiy matin ingliz tilida
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <AvField type="textarea" name="descriptionEng" hidden require value={this.state.engdescription}/>
                                                            <Editor id="eng"
                                                                    initialValue={this.state.engdescription}
                                                                    apiKey="8vznrqbyk30khp4r9jiuzg54xctka8k2p2m5357xkksjieja"
                                                                    init={{
                                                                        plugins: [
                                                                            "advlist autolink lists link image charmap print preview",
                                                                            "searchreplace visualblocks code fullscreen",
                                                                            "insertdatetime table paste imagetools wordcount"
                                                                        ],
                                                                        toolbar: "customInsertButton | insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link",
                                                                        file_picker_types: 'image',
                                                                        extended_valid_elements : "iframe[src|frameborder|style|scrolling|class|width|height|name|align]",
                                                                        file_picker_callback: function (cb, value, meta) {

                                                                            let input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');
                                                                            input.onchange = function () {
                                                                                const formData = new FormData();
                                                                                const imagefile = this.files[0];
                                                                                formData.append("images", imagefile);
                                                                                try {
                                                                                    axios.post(statick.serverUrl+"/api/attachment",formData,{
                                                                                        headers: {
                                                                                            'Content-Type': 'multipart/form-data',
                                                                                            Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                                                                                        }
                                                                                    }).then(images=>{
                                                                                        const imageUrl = images.data[0].imageUrl;
                                                                                        cb(statick.serverUrl+"/"+imageUrl,{title: "Salom!"})
                                                                                    }).catch(e=>{
                                                                                        console.log(e);
                                                                                        toast.error("Rasm qo'shish bilan muamo bor!")
                                                                                    });
                                                                                }catch (e){
                                                                                    console.log(e)
                                                                                }
                                                                            };
                                                                            input.click();
                                                                        }
                                                                    }}
                                                                    onChange={this.handleChange}/>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                </Accordion>
                                            </Col>
                                        </Row>
                                        <Button color="info">Saqlash</Button>
                                    </AvForm>
                                </CardBody>
                            </Card>

                    }
                </div>
            );
        }else{
            return(
                <Redirect to="/login"/>
            )
        }

    }
}

Pages.propTypes = {};

export default Pages;
