import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import axios from 'axios';
import statick from "../../../../const/index";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {Redirect} from "react-router-dom";

class AddVideo extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit= this.handleSubmit.bind(this);
        this.state = {
            token: localStorage.getItem('token'),
            statusToken: true,
            imageId:'',
            selectImage:'',
            galleryStatus:this.props.status
        }
    }
    handleSubmit(e,error,value){
        if(value.title && value.lang && value.description && value.images && value.videoUrl){
            try{
                axios.post(statick.serverUrl+"/api/video",value,{
                    headers: {
                        Authorization: this.state.token //the token is a variable which holds the token
                    }
                })
                    .then(video=>{
                        this.setState({
                            gallery:!this.state.gallery
                        });
                        toast.success("Video qo'shildi");
                    }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Videoni yuklashda hatolik bor!");
                    }

                });
            }catch (e){
                toast.error("Videoni yuklashda hatolik bor!");
            }
        }else{
            toast.error("Iltimos ma'lumotlarni to'liq to'ldiring")
        }
    }
    changePhoto =(e)=>{
        this.setState({
            selectImage:e.target.files[0]
        })
    }
    setImage =()=>{
        if(this.state.selectImage){
            const formData = new FormData();
            const imagefile = this.state.selectImage;
            formData.append("images", imagefile);
            try {
                axios.post(statick.serverUrl+"/api/attachment",formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: this.state.token //the token is a variable which holds the token
                    }
                }).then(images=>{
                    this.setState({
                        imageId:images.data[0].imageUrl
                    });
                    toast.success("Rasm qo'shildi!")
                }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }

                });
            }catch (e){
                console.log(e)
            }
        }
    }
    render() {
        if(this.state.statusToken ){
            return (
                <div className="dsh-video-add">
                    <ToastContainer/>
                    <Card>
                        <CardBody>
                            <h4>Yangi video qo'shish</h4>
                            <AvForm onSubmit={this.handleSubmit}>
                                <CardBody>
                                    <Row>
                                        <Col md="6">
                                            <div className="form-group">
                                                <AvField name="title" label="Sarlavha" required />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="form-group">
                                                <AvField type="select" required name="lang" label="Maqola tili">
                                                    <option disabled={true} value="">Tilni tanlang</option>
                                                    <option value="uz">Uz</option>
                                                    <option value="ru">Ru</option>
                                                    <option value="eng">Eng</option>
                                                </AvField>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <div className="form-group">
                                                <AvField type="textarea" name="description" label="Qisqacha mazmuni" required />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <label htmlFor="file" className="mr-2">
                                                <span className="badge badge-info d-inline-block py-2 px-3">Video uchun rasm yuklang</span>
                                            </label>
                                            <input type="file" accept="image/png, image/jpeg, image/jpg" id="file" hidden={true} onChange={this.changePhoto}/>
                                            <span className="d-inline-block badge badge-success py-2 px-3" onClick={this.setImage}>Saqlash</span>
                                            <AvField required value={this.state.imageId} hidden name="images"/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <div className="form-group">
                                                <AvField type="text" label="Video linkini kiriting" required name="videoUrl"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="text-right">
                                            <Button color="outline-danger" className="mr-2 small" onClick={this.props.status}>Bekor qilish</Button>
                                            <Button color="primary" className="small">Qo'shish</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </AvForm>
                        </CardBody>
                    </Card>
                </div>
            );
        }else{
            return(
                <Redirect to="/login"/>
            )
        }

    }
}

AddVideo.propTypes = {};

export default AddVideo;
