import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as axios  from 'axios';
//COMPONENT TEXT REDACTOR
import { Editor } from '@tinymce/tinymce-react'

import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import statick from "../../../../const/index";
import {Redirect} from "react-router-dom";

class AddArticle extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            token: localStorage.getItem('token'),
            statusToken: true,
            values:'',
            text:'',
            image:'',
            imageUrl:''
        };
        this.handleChange = this.handleChange.bind(this)
    }
    handleSubmit(event, errors, values){
        axios.post(statick.serverUrl+"/api/article", values,{
            headers: {
                Authorization: this.state.token //the token is a variable which holds the token
            }
        })
            .then(article=>{
                if(article.data.article){
                    toast.success("Maqola qo'shildi !", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }else{
                    toast.error(article.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }).catch(e=>{
            const status = e.response.status;
            if(status === 401){
                toast.error("Iltimos tizimga qayta kiring!");
                setTimeout(()=>{
                    localStorage.clear();
                    this.setState({
                        statusToken: false
                    })
                },1000)
            }else{
                toast.error("Rasm qo'shish bilan muamo bor!")
            }
        })
    }
    handleChange(value) {
        this.setState({ text: value.level.content })
    }
    setImage=(e)=>{
        this.setState({
            image: e.target.files[0]
        })
    };
    addImage=()=>{
        if(this.state.image){
            const formData = new FormData();
            const imagefile = this.state.image;
            formData.append("images", imagefile);
            try {
                axios.post(statick.serverUrl+"/api/images",formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: this.state.token //the token is a variable which holds the token
                    }
                }).then(images=>{
                    this.setState({
                        imageUrl:images.data,
                        image:''
                    });
                    toast.success("Rasm qo'shildi!")
                }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }

                });
            }catch (e){
                console.log(e)
            }
        }
    };
    closeModal=()=>{
        document.getElementById("modal").classList.add("d-none");
    }
    render() {
        if(this.state.statusToken ){
            return (
                <div className="add-article">
                    <div className="desc-modal d-none" id="modal">
                        <Card>
                            <CardHeader>
                                Rasm tanlash <span onClick={this.closeModal} className="close btn btn-link">&times;</span>
                            </CardHeader>
                            <CardBody id="div">

                            </CardBody>
                            <CardFooter id="footer">

                            </CardFooter>
                        </Card>
                    </div>
                    <ToastContainer/>
                    <Row>
                        <Col md="12">
                            <h4 className="m-0 py-4">Yangi maqola qo'shish</h4>
                            <Card>
                                <AvForm onSubmit={this.handleSubmit}>
                                    <CardBody>
                                        <Row>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <AvField name="title" label="Sarlavha" required />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <AvField type="select" name="lang" label="Maqola tili">
                                                        <option disabled={true} value="">Tilni tanlang</option>
                                                        <option value="uz">Uz</option>
                                                        <option value="ru">Ru</option>
                                                        <option value="eng">Eng</option>
                                                    </AvField>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="form-group">
                                                    <AvField type="textarea" name="description" label="Qisqacha mazmuni" required />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="form-group">
                                                    <AvField type="textarea" hidden={true} value={this.state.text} name="text" label="Asosiy matin" required />
                                                    <Editor
                                                        initialValue={this.state.description}
                                                        apiKey="qgl1mtmgs4gsrpt05e2qidduwguqyruwnq255ditjw8jwdkh"
                                                        init={{
                                                            plugins: [
                                                                "advlist autolink lists link image charmap print preview",
                                                                "searchreplace visualblocks code fullscreen",
                                                                "insertdatetime table paste imagetools wordcount"
                                                            ],
                                                            toolbar: "customInsertButton | insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link",
                                                            file_picker_types: 'image',
                                                            file_picker_callback: function (cb, value, meta) {
                                                                let div = document.getElementById("div");
                                                                const divChild = div.childNodes[0];
                                                                if(divChild){
                                                                    document.getElementById("div").removeChild(divChild);
                                                                }
                                                                document.getElementById("modal").classList.remove("d-none");
                                                                let imagesArray='';
                                                                let card = document.createElement("div");
                                                                card.classList.add("row");
                                                                try {
                                                                    axios.get(statick.serverUrl+"/api/images").then(images=>{
                                                                        imagesArray = images.data;
                                                                        for(let i = 0; i < imagesArray.length; i++){
                                                                            let cardBody = document.createElement("div");
                                                                            cardBody.classList.add("col-md-2");
                                                                            let img = document.createElement("img");
                                                                            let src = statick.serverUrl+"/api/images/"+imagesArray[i].id;
                                                                            img.setAttribute("src", src);
                                                                            img.setAttribute("id", imagesArray[i].id);
                                                                            img.classList.add("card-img-top","m-2");
                                                                            cardBody.appendChild(img);
                                                                            card.appendChild(cardBody);
                                                                            div.appendChild(card);
                                                                            img.onclick=function (e) {
                                                                                cb("http://sirk.uz/api/images/"+e.target.id,{title: "Salom!"});
                                                                                document.getElementById("modal").classList.add("d-none");
                                                                                document.getElementById("div").removeChild(div.childNodes[0]);
                                                                            }
                                                                        }

                                                                    }).catch(e=>{
                                                                        toast.error("Rasm qo'shish bilan muamo bor!")
                                                                    });
                                                                }catch (e){
                                                                    console.log(e)
                                                                }
                                                                let input = document.createElement('input');
                                                                let buttonImg = document.createElement("div");
                                                                let label =document.createElement("label");
                                                                let textBtn = document.createTextNode("Rasmni kompyuterdan yuklash");
                                                                label.setAttribute("for","file");
                                                                label.classList.add("btn","btn-info");
                                                                label.appendChild(textBtn);
                                                                input.setAttribute('type', 'file');
                                                                input.setAttribute('id', 'file');
                                                                input.setAttribute('accept', 'image/*');
                                                                input.setAttribute('hidden', 'hidden');
                                                                input.onchange = function () {
                                                                    const formData = new FormData();
                                                                    const imagefile = this.files[0];
                                                                    formData.append("images", imagefile);
                                                                    try {
                                                                        axios.post(statick.serverUrl+"/api/images",formData,{
                                                                            headers: {
                                                                                'Content-Type': 'multipart/form-data',
                                                                                Authorization: localStorage.getItem('token'), //the token is a variable which holds the token
                                                                            }
                                                                        }).then(images=>{

                                                                            const imageUrl = images.data;
                                                                            cb("http://sirk.uz/api/images/"+imageUrl,{title: "Salom!"});
                                                                            document.getElementById("modal").classList.add("d-none");
                                                                        }).catch(e=>{
                                                                            const status = e.response.status;
                                                                            if(status === 401){
                                                                                toast.error("Iltimos tizimga qayta kiring!");
                                                                                setTimeout(()=>{
                                                                                    localStorage.clear();
                                                                                    this.setState({
                                                                                        statusToken: false
                                                                                    })
                                                                                },1000)
                                                                            }else{
                                                                                toast.error("Rasm qo'shish bilan muamo bor!")
                                                                            }
                                                                        });
                                                                    }catch (e){
                                                                        console.log(e)
                                                                    }
                                                                };
                                                                buttonImg.appendChild(input);
                                                                buttonImg.appendChild(label);
                                                                let child = document.getElementById("footer").hasChildNodes();
                                                                if(!child){
                                                                    document.getElementById("footer").appendChild(buttonImg);
                                                                }

                                                            }
                                                        }}
                                                        onChange={this.handleChange}/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="form-group">
                                                    <label htmlFor="file" className="badge badge-info font-weight-normal py-3 px-4 mr-2">Maqola uchun rasm yuklang</label>
                                                    <span className="badge btn-primary font-weight-normal py-3 px-4" onClick={this.addImage}>Saqlash</span>
                                                    <input type="file" hidden={true} id="file" onChange={this.setImage}/>
                                                    <AvField required hidden name="images" value={this.state.imageUrl}/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <Button color="primary" className="py-2 px-3 small">Saqlash</Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </AvForm>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        }else{
            return(
                <Redirect to="/login"/>
            )
        }
    }

}

AddArticle.propTypes = {};

export default AddArticle;
