import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, CardBody, CardImgOverlay, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from "react-accessible-accordion";
import 'react-accessible-accordion/dist/fancy-example.css'
import statick from "../../../../../const/index";
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import axios from "axios";

class Add extends Component {
    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state= {
            token: localStorage.getItem('token'),
            modal: false,
            galleryImages:[],
            titleImg:(this.props.model ? this.props.model.journal.img : ""),
            uploadFile:'',
            journalGallery:[],
            model: this.props.model,
        }
    }
    handleSubmit(event,error,value){
        if(this.state.model){
            if(error.length===0){
                const id = this.state.model.journal.id;
                value.imgId = this.state.journalGallery;
                axios.put(statick.serverUrl+"/api/journal/"+id, value)
                    .then(image=>{
                        const fn = this.props.update;
                        toast.success("O'zgartirildi!");
                        fn()
                    }).catch(e=>{
                    console.log(e)
                })
            }
        }else{
            if(error.length===0){
                value.imgId = this.state.journalGallery;
                axios.post(statick.serverUrl+"/api/journal",value,{
                    headers: {
                        Authorization: this.state.token //the token is a variable which holds the token
                    }
                })
                    .then(image=>{
                        const fn = this.props.update;
                        toast.success("O'zgartirildi!");
                        fn();
                    }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Journal yaratish bilan muamo bor!")
                    }
                })
            }
        }
    }
    accordionChange=(e)=> {
        try {
            axios.get(statick.serverUrl + "/api/images")
                .then(images => {
                    this.setState({
                        galleryImages: images.data
                    })
                })
                .catch(e => {
                    console.log(e)
                })
        } catch (e) {
            console.log(e)
        }
    };
    addTitleImg=(e)=>{
        this.setState({
            titleImg: e.target.id
        })
    };//Title IMG ni o'rnatish
    changeImage=(e)=>{
        this.setState({
            uploadFile: e.target.files[0]
        })
    }//Rasmni inputdan olish
    uploadImage=()=>{
        if(this.state.uploadFile){
            const formData = new FormData();
            const imagefile = this.state.uploadFile;
            formData.append("images", imagefile);
            try {
                axios.post(statick.serverUrl+"/api/images",formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: this.state.token //the token is a variable which holds the token
                    }
                }).then(images=>{
                    this.setState({
                        titleImg:images.data
                    });
                    toast.success("Rasm qo'shildi!")
                }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }

                });
            }catch (e){
                console.log(e)
            }
        }
    }// Rasmni foydalanvchidan yuklash
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
        if(!this.state.modal){
            try {
                axios.get(statick.serverUrl + "/api/images")
                    .then(images => {
                        this.setState({
                            galleryImages: images.data
                        })
                    })
                    .catch(e => {
                        console.log(e)
                    })
            } catch (e) {
                console.log(e)
            }
        }
    }//Modal
    addGallery=(e)=>{

        let gallery = this.state.journalGallery;
        const id = e.target.id;
        let search = false;
        let i = 0;

        if(gallery.length!=0){
            while (i<gallery.length){
                if(gallery[i] ==id){
                    search = true;
                    gallery.splice(i,1);
                }else {
                    i++
                }
            }
            if(!search){
                gallery.push(e.target.id);
            }
        }else{
            gallery.push(e.target.id);
        }
        this.setState({
            journalGallery: gallery
        })
    }
    render() {
        const model = this.props.model;
        return (
            <div className="bg-white px-4">
                <ToastContainer/>
                <Row>
                   <Col md="12">
                       <h4 className="my-0 py-4">Yangi elektron jurnal yaratish</h4>
                   </Col>
                </Row>
                <AvForm onSubmit={this.handleSubmit} model={model.journal}>
                    <Row>
                        <Col md="4">
                            <AvField type="text" label="Sarlavha o'zbek tilida" required name="titleUz" placeholder="Sarlavha o'zbek tilida"/>
                        </Col>
                        <Col md="4">
                            <AvField type="text" label="Sarlavha o'zbek tilida" required name="titleRu" placeholder="Sarlavha o'zbek tilida"/>
                        </Col>
                        <Col md="4">
                            <AvField type="text" label="Sarlavha o'zbek tilida" required name="titleEng" placeholder="Sarlavha o'zbek tilida"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <AvField type="text" required={true} hidden value={this.state.titleImg} name="titleImg"/>
                            <input type="file" onChange={this.changeImage} hidden id="file"/>
                            <label htmlFor="file">
                                <div className="btn btn-outline-info">
                                    Rasm yukash
                                </div>
                            </label>
                            <Button color="primary" className="ml-2" onClick={this.uploadImage}>
                                Rasmni saqlash
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Accordion onChange={this.accordionChange} className="border-0">
                                <AccordionItem className="my-4 border border-light">
                                    <AccordionItemTitle>
                                        <span className="pl-4">Yoki mavjudlaridan birini tanlang</span>
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                        <Row>
                                            {this.state.galleryImages.length !==0 ?
                                                this.state.galleryImages.map((item,i)=>{
                                                    return(
                                                        <Col key={i} md="2">
                                                            <Card className="my-4">
                                                                <img  className="card-img-top" src={statick.serverUrl+"/api/images/"+item.id} alt={item.id}/>
                                                                <CardBody>
                                                                    <input type="radio" name="titleImage" id={item.id} onChange={this.addTitleImg}/>
                                                                    <label htmlFor={item.id}>Rasmni tanlash</label>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    )
                                                })
                                                : <h4>Rasmlar mavjud emas</h4>
                                            }
                                        </Row>
                                    </AccordionItemBody>
                                </AccordionItem>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row className="border-top border-light py-4">
                        <Col md="4">
                            <Button color="outline-primary" onClick={this.toggle}>Galereya yaratish</Button>
                            <Modal size="xl" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                                <ModalHeader toggle={this.toggle}>
                                    Galereya uchun rasm tanlang
                                </ModalHeader>
                                <ModalBody>
                                    <Row>
                                        {this.state.galleryImages.length !==0 ?
                                            this.state.galleryImages.map((item,i)=>{
                                                return(
                                                    <Col key={i} md="3">
                                                        <Card className="my-4">
                                                            <img  className="card-img-top" src={statick.serverUrl+"/api/images/"+item.id} alt={item.id}/>
                                                            <CardBody>
                                                                <input type="checkbox" id={item.id} onChange={this.addGallery}/>
                                                                <label htmlFor={item.id}>Rasmni tanlash</label>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                )
                                            })
                                            : <h4>Rasmlar mavjud emas</h4>
                                        }
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="success" onClick={this.toggle}>Saqlash</Button>
                                </ModalFooter>
                            </Modal>
                        </Col>
                        <Col className="text-right" md={{size:4,offset:4}}>
                            {
                                this.state.model ? <Button color="outline-danger" className="mr-2" onClick={this.props.update}>Bekor qilish</Button>:""
                            }
                            <Button color="primary">
                                Saqlash
                            </Button>
                        </Col>
                    </Row>
                </AvForm>
            </div>
        );
    }
}

Add.propTypes = {
    update: PropTypes.func
};

export default Add;
