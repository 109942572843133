import React, {Component} from 'react';

//=======================Include Library
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
//=======================Styles
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
//=======================Components
import Home from '../pages/home';
import Header from '../components/header';
import Footer from '../components/footer';
import Dashboard from "../pages/dashboard/index";
import Article from "../pages/singleArticle/index";
import AllArticle from "../pages/allArticle/index";
import AllVideo from "../pages/AllVideo";
import AllGalery from "../pages/allGallery/index";
import About from "../pages/pages/about";
import Working from "../pages/pages/working";
import Contacts from "../pages/pages/contacts";
import Info from "../pages/pages/info";
import Service from "../pages/pages/service";
import Pages from "../pages/pages/index";
import Repertoire from "../pages/repertorie/index";
import statick from "../const/index";
import Artists from "../pages/pages/artists";
import Events from "../pages/pages/events";
import Ticket from "../pages/pages/ticket";
import Login from "../pages/login/index";
import Loader from "../components/loader/loader";
import AllQuestions from "../pages/allQuestions";
import AllRepertories from "../pages/allRepertories/index";
import Search from "../pages/search/index";
import Journal from "../pages/journal/index";
import NotFounded from "../pages/404/index";




class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eye: false,
            lang: "uz",
            statikLang: statick.lang.ru,
            loader: true,
            videoId: "",
            redirect: false,
            searchText:""
        };
    }
    componentDidMount(){
        if(this.state.lang === "uz"){
            this.setState({statikLang: statick.lang.uz})
        }else if(this.state.lang === "ru"){
            this.setState({statikLang: statick.lang.ru})
        }else if(this.state.lang === "eng"){
            this.setState({statikLang: statick.lang.eng})
        }
        const timeLoader = setTimeout(()=>{
            if(this.state.loader){
                this.setState({
                    loader:false
                })
            }
        },2000)
    }
    eye = ()=>{
        this.setState({
            eye:!this.state.eye
        });
        const div = document.getElementById("root").querySelectorAll("div");

        this.state.eye ? document.body.classList.add("eye-no-bgimg") : document.body.classList.remove("eye-no-bgimg");

        div.forEach(item=>{
            this.state.eye?item.classList.add("img-eye"):item.classList.remove("img-eye");
        });
    };
    setLang =(lang)=>{
        if(lang === "uz"){
            this.setState({statikLang: statick.lang.uz})
        }else if(lang === "ru"){
            this.setState({statikLang: statick.lang.ru})
        }else if(lang === "eng"){
            this.setState({statikLang: statick.lang.eng})
        }
        this.setState({
            lang: lang,
            redirect:true
        })
    };
    updateVideoId =(id)=>{
        this.setState({
            videoId: id
        })
    };
    setSearchText = (event)=>{
        this.setState({
            searchText: event.target.value
        })
    }
    render() {
        let urlName = this.props.location;
        const statickLang = this.state.statikLang;
        const lang = this.state.lang;

        return (
                <div>
                    {urlName.pathname === '/dashboard' ? "" :
                        urlName.pathname === '/search' ?
                           "" : (<Header eye={this.eye} setSearchText={this.setSearchText} lang={lang} statikLang = {statick.lang}  setlang={this.setLang}/>)}
                    <Switch>
                        <Route exact path="/" render={(props) => <Home lang={this.state.lang} statikLang = {statickLang}/>}/>
                        <Route  path="/about" render={(props) => <About lang={this.state.lang} statikLang = {statickLang}/>}/>
                        <Route  path="/working" render={(props) => <Working lang={this.state.lang} statikLang = {statickLang}/>}/>
                        <Route  path="/service" render={(props) => <Service lang={this.state.lang} statikLang = {statickLang}/>}/>
                        <Route  path="/info" render={(props) => <Info lang={this.state.lang} statikLang = {statickLang}/>}/>
                        <Route  path="/contacts" render={(props) => <Contacts lang={this.state.lang} statikLang = {statickLang}/>}/>
                        <Route  path="/artists" render={(props) => <Artists lang={this.state.lang} statikLang = {statickLang}/>}/>
                        <Route  path="/events" render={(props) => <Events lang={this.state.lang} statikLang = {statickLang}/>}/>
                        <Route  path="/ticket" render={(props) => <Ticket lang={this.state.lang} statikLang = {statickLang}/>}/>
                        <Route  path="/login" render={(props) => <Login lang={this.state.lang} statikLang = {statickLang}/>}/>
                        <Route path="/dashboard" render={(props) => <Dashboard/>}/>
                        <Route path="/all-article" render ={(props) => <AllArticle lang={this.state.lang} statickLang = {statickLang}/>}/>
                        <Route path="/all-video" render = {(props) => <AllVideo lang={this.state.lang} statickLang = {statickLang}/>}/>
                        <Route path="/gallery" render = {(props)=> <AllGalery lang={this.state.lang} statickLang={statickLang}/>}/>
                        <Route path="/questions" render = {(props)=> <AllQuestions lang={this.state.lang} statickLang={statickLang}/>}/>
                        <Route path="/all-repertories" render = {(props)=> <AllRepertories lang={this.state.lang} statickLang={statickLang}/>}/>
                        <Route path="/journal/:id" render = {(match)=> <Journal lang={this.state.lang} statickLang={statickLang}/>}/>
                        <Route path="/journal/" render = {(props)=> <Journal lang={this.state.lang} statickLang={statickLang}/>}/>
                        <Route path="/search" render = {(props)=> <Search  setlang={this.setLang} search={this.state.searchText} lang={this.state.lang} statickLang={statickLang}/>}/>
                        <Route path="/article/:id" render = {(match)=><Article lang={this.state.lang} statickLang={statickLang}/>}/>
                        <Route path="/repertoire" component = {Repertoire}/>
                        <Route render = {(props)=><NotFounded lang={this.state.lang} statickLang={statickLang}/>}/>
                    </Switch>
                    {urlName.pathname === '/dashboard' ? "" : (<Footer lang={this.state.lang} statickLang={statickLang}/>)}
                </div>
            );
    }
}

export default withRouter(App);
