import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
//COMPONENT TEXT REDACTOR
import { Editor } from '@tinymce/tinymce-react';

import statick from "../../../../const/index";
import {ToastContainer,toast} from "react-toastify";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import ReactTable from "react-table";
import "react-table/react-table.css"
import {Redirect} from "react-router-dom";

class Repertoire extends Component {

    //REACT QUILL EDITOR SETTINGS
    modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'image','code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ],
    };
    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];
    submitClick =(a)=>{
        console.log(a)
    }
    constructor(props) {
        super(props);
        this.handleChange= this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            token: localStorage.getItem('token'),
            statusToken: true,
            pages:[],
            status:false,
            selectImage:'',
            description:'',
            onePage:'',
            updated:false
        }
    }
    componentDidMount(){
        try{
            axios.get(statick.serverUrl+"/api/repertoire")
                .then(pages=>{
                    this.setState({
                        pages: pages.data,
                    })
                }).catch(e=>{
                console.log(e);
            });
        }catch (e){
            toast.error("Rasm yuklash bilan hatolik yuz berdi!")
        }
    };
    addRepertoire =()=>{
        this.setState({
            status:!this.state.status,
            selectImage:'',
            description:'',
            onePage:'',
            updated:false
        })
    }
    handleSubmit(e,error,value){
        if(value.title && value.lang && value.description && value.images){
            if(this.state.updated){
                const id = this.state.onePage.id;
                try{
                    axios.put(statick.serverUrl+"/api/repertoire/"+id,value,{
                        headers: {
                            Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                        }
                    })
                        .then(repertoire=>{
                            this.setState({
                                status:!this.state.status,
                                selectImage:'',
                                description:'',
                                pages: repertoire.data
                            });
                            toast.success("Taxrirlandi!");
                        }).catch(e=>{
                        console.log(e);
                        toast.error("Hatolik bor!");
                    });
                }catch (e){
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Repertuar yaratish bilan hatolik yuz berdi!")
                    }

                }
            }else{
                try{
                    axios.post(statick.serverUrl+"/api/repertoire",value,{
                        headers: {
                            Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                        }
                    })
                        .then(repertoire=>{
                            this.setState({
                                status:!this.state.status,
                                selectImage:'',
                                description:'',
                                pages: repertoire.data
                            });
                            toast.success("Qo'shildi");
                        }).catch(e=>{
                        const status = e.response.status;
                        if(status === 401){
                            toast.error("Iltimos tizimga qayta kiring!");
                            setTimeout(()=>{
                                localStorage.clear();
                                this.setState({
                                    statusToken: false
                                })
                            },1000)
                        }else{
                            toast.error("Hatolik bor!");
                        }

                    });
                }catch (e){
                    toast.error("Repertuar yaratish bilan hatolik yuz berdi!")
                }
        }
        }else{
        toast.error("Iltimos ma'lumotlarni to'liq to'ldiring");
        }
    };
    handleChange(value){
        this.setState({
            description: value.level.content
        })
    }
    Delete =(id)=>{
        if(id){
            try{
                axios.delete(statick.serverUrl+"/api/repertoire/"+id,{
                    headers: {
                        Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                    }
                })
                    .then(repertoire=>{
                        this.setState({
                            pages: repertoire.data
                        });
                        toast.success("O'chirildi!");
                    }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Hatolik bor!");
                    }
                });
            }catch (e){
                toast.error("Repertuar yaratish bilan hatolik yuz berdi!")
            }
        }
    };
    Update =(id)=>{
        if(id){
            try{
                axios.get(statick.serverUrl+"/api/repertoire/"+id,{
                    headers: {
                        Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                    }
                })
                    .then(repertoire=>{
                        this.setState({
                            status:!this.state.status,
                            selectImage:repertoire.data.imageUrl,
                            description:repertoire.data.description,
                            onePage:repertoire.data,
                            updated:true
                        });
                        console.log(this.state.onePage)
                    }).catch(e=>{
                    console.log(e);
                    toast.error("Hatolik bor!");
                });
            }catch (e){
                toast.error("Repertuar yaratish bilan hatolik yuz berdi!")
            }
        }
    }
    changePhoto =(e)=>{
        this.setState({
            selectImage:e.target.files[0]
        })
    }
    setImage =()=>{
        if(this.state.selectImage){
            const formData = new FormData();
            const imagefile = this.state.selectImage;
            formData.append("images", imagefile);
            try {
                axios.post(statick.serverUrl+"/api/attachment",formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                    }
                }).then(images=>{
                    this.setState({
                        selectImage:images.data[0].imageUrl
                    });
                    console.log(this.state.selectImage);
                    toast.success("Rasm qo'shildi!")
                }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }

                });
            }catch (e){
                console.log(e)
            }
        }
    }


    //REACT TABLE SETTINGS
    columns =  [{
        Header: 'T/R',
        accessor: 'id' // String-based value accessors!
    }, {
        Header: 'Title',
        accessor: 'title',
    },{
        Header: "Amallar", // Custom header components!
        Cell: props => (<div>
                            <Button color="outline-info" className="mr-2" onClick={()=>this.Update(props.original.id)}>Taxrirlash</Button>
                            <Button color="outline-danger" onClick={()=>this.Delete(props.original.id)}>&times;</Button>
                         </div>)
    }]
    render() {
        if(this.state.statusToken ){
            return (
                <div>
                    <ToastContainer/>
                    <Row>
                        <Col md="6">
                            <h4>Repertuarlar</h4>
                        </Col>
                        <Col md="6" className="text-right">
                            <Button color="info" className="small" onClick={this.addRepertoire}>
                                {this.state.status?<span>Bekor qilish</span>:<span> Repertuar qo'shish</span>}
                            </Button>
                        </Col>
                    </Row>
                    <Card className="my-4">
                        <CardBody>
                            {
                                this.state.status ?
                                    <AvForm onSubmit={this.handleSubmit} model={this.state.updated ? this.state.onePage:''}>
                                        <CardBody>
                                            <Row>
                                                <Col md="6">
                                                    <div className="form-group">
                                                        <AvField name="title" label="Sarlavha" required />
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="form-group">
                                                        <AvField type="select" required name="lang" label="Maqola tili">
                                                            <option disabled={true} value="">Tilni tanlang</option>
                                                            <option value="uz">Uz</option>
                                                            <option value="ru">Ru</option>
                                                            <option value="eng">Eng</option>
                                                        </AvField>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <label htmlFor="file" className="mr-2">
                                                        <span className="badge badge-info d-inline-block py-2 px-3">Sarlavha uchun rasm yuklang</span>
                                                    </label>
                                                    <input type="file" accept="image/png, image/jpeg, image/jpg" id="file" hidden={true} onChange={this.changePhoto}/>
                                                    <span className="d-inline-block badge badge-success py-2 px-3" onClick={this.setImage}>Saqlash</span>
                                                    <AvField required value={this.state.selectImage} hidden name="images"/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <div className="form-group">
                                                        <label htmlFor="text">Qisqacha mazmuni</label>
                                                        <Editor
                                                            initialValue={this.state.description}
                                                            apiKey="8vznrqbyk30khp4r9jiuzg54xctka8k2p2m5357xkksjieja"
                                                            init={{
                                                                plugins: [
                                                                    "advlist autolink lists link image charmap print preview",
                                                                    "searchreplace visualblocks code fullscreen",
                                                                    "insertdatetime table paste imagetools wordcount"
                                                                ],
                                                                toolbar: "customInsertButton | insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link",
                                                                file_picker_types: 'image',
                                                                file_picker_callback: function (cb, value, meta) {

                                                                    let input = document.createElement('input');
                                                                    input.setAttribute('type', 'file');
                                                                    input.setAttribute('accept', 'image/*');
                                                                    input.onchange = function () {
                                                                        const formData = new FormData();
                                                                        const imagefile = this.files[0];
                                                                        formData.append("images", imagefile);
                                                                        try {
                                                                            axios.post(statick.serverUrl+"/api/attachment",formData,{
                                                                                headers: {
                                                                                    'Content-Type': 'multipart/form-data',
                                                                                    Authorization: localStorage.getItem('token'),
                                                                                }
                                                                            }).then(images=>{
                                                                                const imageUrl = images.data[0].imageUrl;
                                                                                cb(statick.serverUrl+"/"+imageUrl,{title: "Salom!"})
                                                                            }).catch(e=>{
                                                                                console.log(e);
                                                                                toast.error("Rasm qo'shish bilan muamo bor!")
                                                                            });
                                                                        }catch (e){
                                                                            console.log(e)
                                                                        }
                                                                    };
                                                                    input.click();
                                                                }
                                                            }}
                                                            onChange={this.handleChange}/>
                                                        <AvField hidden={true} type="textarea" name="description" value={this.state.description} required />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12" className="text-right">
                                                    <Button color="outline-danger" className="mr-2 small" onClick={this.addRepertoire}>Bekor qilish</Button>
                                                    <Button color="primary" className="small">Qo'shish</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </AvForm>:
                                    <ReactTable
                                        data={this.state.pages} defaultPageSize={10}
                                        previousText={'Oldingisi'}
                                        nextText={'Keyingisi'}
                                        loadingText={'Kuting...'}
                                        noDataText={'Repertuarlar mavjud emas!'}
                                        pageText={'Page'}
                                        ofText={'dan'}
                                        rowsText={'qator'}
                                        columns={this.columns}
                                    />
                            }
                        </CardBody>
                    </Card>
                </div>
            );
        }else{
            return(
                <Redirect to="/login"/>
            )
        }

    }
}

Repertoire.propTypes = {};

export default Repertoire;
