import React, {Component} from 'react';
import axios from 'axios';
import {Button, Col, Row} from "reactstrap";
import Add from "../components/add";
import statick from "../../../../../const/index";
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AllJournal from "../components/all";

class Journals extends Component {
    constructor(props){
        super(props);
        this.state = {
            allJournal: [],
            status: true,
            upStatus: false,
            model:""
        }
    }

    setStatus=()=>{
        this.setState({
            status: !this.state.status
        })
    };
    unSetStatus=()=>{
        this.setState({
            status: false
        })
    };
    Update=(id)=>{
        try{
            axios.get(statick.serverUrl+"/api/journal/"+id)
                .then(journals=>{
                    this.setState({
                        model: journals.data,
                        status: !this.state.status
                    })
                })
                .catch(e=>{
                    console.log(e);
                })
        }catch (e){
            console.log(e)
        }
    };
    Delete=(id)=>{
        try{
            axios.delete(statick.serverUrl+"/api/journal/"+id)
                .then(journals=>{
                    this.setState({
                        allJournal: journals.data
                    })
                    toast.success("O'chirildi!")
                })
                .catch(e=>{
                    console.log(e);
                })
        }catch (e){
            console.log(e)
        }
    };
    columns =  [{
        Header: 'Id',
        accessor: 'journal.id' // String-based value accessors!
    }, {
        Header: 'Sarlavha',
        accessor: 'journal.titleUz',
    },{
        Header: "Amallar", // Custom header components!
        Cell: props => (<div>
            <Button color="outline-info" className="mr-2" onClick={()=>this.Update(props.original.journal.id)}>Taxrirlash</Button>
            <Button color="outline-danger" onClick={()=>this.Delete(props.original.journal.id)}>&times;</Button>
        </div>)
    }];
    render() {
        return (
            <div>
                <ToastContainer/>
                <Row className="py-4">
                    <Col md="12" className="text-right">
                        <Button color="info" className="py-0 px-4" onClick={this.setStatus}>
                            {this.state.status?<small> Journal qo'shish </small>:<small>Bekor qilish</small>}
                        </Button>
                    </Col>
                </Row>
                {
                    this.state.status ?
                        <AllJournal status={this.state.status} update={this.unSetStatus}/> : <Add update={this.setStatus} model={this.state.model}/>
                }
            </div>
        );
    }
}

Journals.propTypes = {};

export default Journals;
