import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from "react-accessible-accordion";
import {AvField, AvForm} from "availity-reactstrap-validation";
import { Editor } from '@tinymce/tinymce-react'
import 'react-accessible-accordion/dist/fancy-example.css'
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import axios from "axios";
import statick from "../../../../../const/index";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Row} from "reactstrap";


class AddDescription extends Component {
    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state={
            token: localStorage.getItem('token'),
            descriptionUz: (this.props.updateItem ? this.props.updateItem.descriptionUz : ""),
            descriptionRu: (this.props.updateItem ? this.props.updateItem.descriptionRu : ""),
            descriptionEng: (this.props.updateItem ? this.props.updateItem.descriptionEng : ""),
            journal:[],
            model: this.props.updateItem,
            status: this.props.update
        }
    }
    componentDidMount(){
        try {
            axios.get(statick.serverUrl+"/api/journal")
                .then(journal=>{
                    this.setState({
                        journal: journal.data
                    })
                })
                .catch(e=>{
                    console.log(e)
                })
        }catch (e){
            console.log(e)
        }
    }
    handleSubmit(event,error,value){
        if(!this.state.status){
            if(error.length ===0){
                const id = this.state.model.id;
                try {
                    axios.put(statick.serverUrl+"/api/journalDesc/"+id,value)
                        .then(desc=>{
                            toast.success("Sahifa o'zgartirildi!");
                            const fn = this.props.setStatus;
                            fn();
                        }).catch(e=>{
                        console.log(e)
                    })
                }catch (e){
                    console.log(e)
                }
            }
        }else{
            if(error.length ===0){
                try {
                    axios.post(statick.serverUrl+"/api/journalDesc",value,{
                        headers: {
                            Authorization: this.state.token //the token is a variable which holds the token
                        }
                    })
                        .then(desc=>{
                            toast.success("Sahifa qo'shildi")
                        }).catch(e=>{
                        const status = e.response.status;
                        if(status === 401){
                            toast.error("Iltimos tizimga qayta kiring!");
                            setTimeout(()=>{
                                localStorage.clear();
                                this.setState({
                                    statusToken: false
                                })
                            },1000)
                        }else{
                            toast.error("Rasm qo'shish bilan muamo bor!")
                        }
                    })
                }catch (e){
                    console.log(e)
                }
            }
        }

    }
    handleChange=(e)=>{
       const lang = e.target.id;
       const text = e.target.getContent();
       if(lang==="uz"){
           this.setState({
               descriptionUz: text
           })
       }else if(lang==="ru"){
           this.setState({
               descriptionRu: text
           })
       }else if(lang==="eng"){
           this.setState({
               descriptionEng: text
           })
       }
    }
    closeModal=()=>{
        document.getElementById("modal").classList.add("d-none");
    }
    render() {

        return (
            <div>
                <div className="desc-modal d-none" id="modal">
                    <Card>
                        <CardHeader>
                            Rasm tanlash <span onClick={this.closeModal} className="close btn btn-link">&times;</span>
                        </CardHeader>
                        <CardBody id="div">

                        </CardBody>
                        <CardFooter id="footer">

                        </CardFooter>
                    </Card>
                </div>
                <ToastContainer/>
                <AvForm onSubmit={this.handleSubmit} model={this.state.model}>
                    <Accordion className="border-0">
                        <AccordionItem expanded={true} className="my-4 border border-light">
                            <AccordionItemTitle>
                                <AvField type="textarea" required hidden name="descriptionUz" value={this.state.descriptionUz}/>
                                Asosiy matin o'zbek tilida
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <AvField name="descriptionUz" type="textarea" hidden required value={this.state.descriptionUz}/>
                                <Editor id="uz"
                                        initialValue={this.state.descriptionUz}
                                        apiKey="qgl1mtmgs4gsrpt05e2qidduwguqyruwnq255ditjw8jwdkh"
                                        init={{
                                            plugins: [
                                                "advlist autolink lists link image charmap print preview",
                                                "searchreplace visualblocks code fullscreen",
                                                "insertdatetime table paste imagetools wordcount"
                                            ],
                                            toolbar: "customInsertButton | insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link",
                                            file_picker_types: 'image',
                                            extended_valid_elements : "iframe[src|frameborder|style|scrolling|class|width|height|name|align]",
                                            file_picker_callback: function (cb, value, meta) {

                                                let div = document.getElementById("div");
                                                const divChild = div.childNodes[0];
                                                if(divChild){
                                                    document.getElementById("div").removeChild(divChild);
                                                }
                                                document.getElementById("modal").classList.remove("d-none");
                                                let imagesArray='';
                                                let card = document.createElement("div");
                                                card.classList.add("row");
                                                try {
                                                    axios.get(statick.serverUrl+"/api/images").then(images=>{
                                                        imagesArray = images.data;
                                                        for(let i = 0; i < imagesArray.length; i++){
                                                            let cardBody = document.createElement("div");
                                                            cardBody.classList.add("col-md-2");
                                                            let img = document.createElement("img");
                                                            let src = statick.serverUrl+"/api/images/"+imagesArray[i].id;
                                                            img.setAttribute("src", src);
                                                            img.setAttribute("id", imagesArray[i].id);
                                                            img.classList.add("card-img-top","m-2");
                                                            cardBody.appendChild(img);
                                                            card.appendChild(cardBody);
                                                            div.appendChild(card);
                                                            img.onclick=function (e) {
                                                                 cb(statick.serverUrl+"/api/images/"+e.target.id,{title: "Salom!"});
                                                                document.getElementById("modal").classList.add("d-none");
                                                                document.getElementById("div").removeChild(div.childNodes[0]);
                                                            }
                                                        }

                                                    }).catch(e=>{
                                                        toast.error("Rasm qo'shish bilan muamo bor!")
                                                    });
                                                    }catch (e){
                                                        console.log(e)
                                                    }
                                                let input = document.createElement('input');
                                                let buttonImg = document.createElement("div");
                                                let label =document.createElement("label");
                                                let textBtn = document.createTextNode("Rasmni kompyuterdan yuklash");
                                                label.setAttribute("for","file");
                                                label.classList.add("btn","btn-info");
                                                label.appendChild(textBtn);
                                                input.setAttribute('type', 'file');
                                                input.setAttribute('id', 'file');
                                                input.setAttribute('accept', 'image/*');
                                                input.setAttribute('hidden', 'hidden');
                                                input.onchange = function () {
                                                    const formData = new FormData();
                                                    const imagefile = this.files[0];
                                                    formData.append("images", imagefile);
                                                    try {
                                                        axios.post(statick.serverUrl+"/api/images",formData,{
                                                            headers: {
                                                                'Content-Type': 'multipart/form-data',
                                                                Authorization: localStorage.getItem('token'), //the token is a variable which holds the token
                                                            }
                                                        }).then(images=>{
                                                            const imageUrl = images.data;
                                                            cb(statick.serverUrl+"/api/images/"+imageUrl,{title: "Salom!"});
                                                            document.getElementById("modal").classList.add("d-none");
                                                        }).catch(e=>{
                                                            const status = e.response.status;
                                                            if(status === 401){
                                                                toast.error("Iltimos tizimga qayta kiring!");
                                                                setTimeout(()=>{
                                                                    localStorage.clear();
                                                                    this.setState({
                                                                        statusToken: false
                                                                    })
                                                                },1000)
                                                            }else{
                                                                toast.error("Rasm qo'shish bilan muamo bor!")
                                                            }
                                                        });
                                                    }catch (e){
                                                        console.log(e)
                                                    }
                                                };
                                                buttonImg.appendChild(input);
                                                buttonImg.appendChild(label);
                                                let child = document.getElementById("footer").hasChildNodes();
                                                if(!child){
                                                    document.getElementById("footer").appendChild(buttonImg);
                                                }

                                            }
                                        }}
                                        onChange={this.handleChange}/>
                            </AccordionItemBody>
                        </AccordionItem>
                        <AccordionItem className="my-4 border border-light">
                            <AccordionItemTitle>
                                <AvField type="textarea" required hidden name="descriptionRu" value={this.state.descriptionRu}/>
                                Asosiy matin rus tilida
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <AvField name="descriptionRu" type="textarea" hidden required value={this.state.descriptionRu}/>
                                <Editor id="ru"
                                        initialValue={this.state.descriptionRu}
                                        apiKey="qgl1mtmgs4gsrpt05e2qidduwguqyruwnq255ditjw8jwdkh"
                                        init={{
                                            plugins: [
                                                "advlist autolink lists link image charmap print preview",
                                                "searchreplace visualblocks code fullscreen",
                                                "insertdatetime table paste imagetools wordcount"
                                            ],
                                            toolbar: "customInsertButton | insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link",
                                            file_picker_types: 'image',
                                            extended_valid_elements : "iframe[src|frameborder|style|scrolling|class|width|height|name|align]",
                                            file_picker_callback: function (cb, value, meta) {

                                                let div = document.getElementById("div");
                                                const divChild = div.childNodes[0];
                                                if(divChild){
                                                    document.getElementById("div").removeChild(divChild);
                                                }
                                                document.getElementById("modal").classList.remove("d-none");
                                                let imagesArray='';
                                                let card = document.createElement("div");
                                                card.classList.add("row");
                                                try {
                                                    axios.get(statick.serverUrl+"/api/images").then(images=>{
                                                        imagesArray = images.data;
                                                        for(let i = 0; i < imagesArray.length; i++){
                                                            let cardBody = document.createElement("div");
                                                            cardBody.classList.add("col-md-2");
                                                            let img = document.createElement("img");
                                                            let src = statick.serverUrl+"/api/images/"+imagesArray[i].id;
                                                            img.setAttribute("src", src);
                                                            img.setAttribute("id", imagesArray[i].id);
                                                            img.classList.add("card-img-top","m-2");
                                                            cardBody.appendChild(img);
                                                            card.appendChild(cardBody);
                                                            div.appendChild(card);
                                                            img.onclick=function (e) {
                                                                cb(statick.serverUrl+"/api/images/"+e.target.id,{title: "Salom!"});
                                                                document.getElementById("modal").classList.add("d-none");
                                                                document.getElementById("div").removeChild(div.childNodes[0]);
                                                            }
                                                        }

                                                    }).catch(e=>{
                                                        console.log(e);
                                                        toast.error("Rasm qo'shish bilan muamo bor!")
                                                    });
                                                }catch (e){
                                                    console.log(e)
                                                }
                                                let input = document.createElement('input');
                                                let buttonImg = document.createElement("div");
                                                let label =document.createElement("label");
                                                let textBtn = document.createTextNode("Rasmni kompyuterdan yuklash");
                                                label.setAttribute("for","file");
                                                label.classList.add("btn","btn-info");
                                                label.appendChild(textBtn);
                                                input.setAttribute('type', 'file');
                                                input.setAttribute('id', 'file');
                                                input.setAttribute('accept', 'image/*');
                                                input.setAttribute('hidden', 'hidden');
                                                input.onchange = function () {
                                                    const formData = new FormData();
                                                    const imagefile = this.files[0];
                                                    formData.append("images", imagefile);
                                                    try {
                                                        axios.post(statick.serverUrl+"/api/images",formData,{
                                                            headers: {
                                                                'Content-Type': 'multipart/form-data',
                                                                Authorization: localStorage.getItem('token'), //the token is a variable which holds the token
                                                            }
                                                        }).then(images=>{
                                                            const imageUrl = images.data;
                                                            cb(statick.serverUrl+"/api/images/"+imageUrl,{title: "Salom!"});
                                                            document.getElementById("modal").classList.add("d-none");
                                                        }).catch(e=>{
                                                            const status = e.response.status;
                                                            if(status === 401){
                                                                toast.error("Iltimos tizimga qayta kiring!");
                                                                setTimeout(()=>{
                                                                    localStorage.clear();
                                                                    this.setState({
                                                                        statusToken: false
                                                                    })
                                                                },1000)
                                                            }else{
                                                                toast.error("Rasm qo'shish bilan muamo bor!")
                                                            }
                                                        });
                                                    }catch (e){
                                                        console.log(e)
                                                    }
                                                };
                                                buttonImg.appendChild(input);
                                                buttonImg.appendChild(label);
                                                let child = document.getElementById("footer").hasChildNodes();
                                                if(!child){
                                                    document.getElementById("footer").appendChild(buttonImg);
                                                }

                                            }
                                        }}
                                        onChange={this.handleChange}/>
                            </AccordionItemBody>
                        </AccordionItem>
                        <AccordionItem className="my-4 border border-light">
                            <AccordionItemTitle>
                                <AvField type="textarea" required hidden name="descriptionEng" value={this.state.descriptionEng}/>
                                Asosiy matin ingliz tilida
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <AvField type="textarea" name="descriptionEng" hidden required value={this.state.descriptionEng}/>
                                <Editor id="eng"
                                        initialValue={this.state.descriptionEng}
                                        apiKey="qgl1mtmgs4gsrpt05e2qidduwguqyruwnq255ditjw8jwdkh"
                                        init={{
                                            plugins: [
                                                "advlist autolink lists link image charmap print preview",
                                                "searchreplace visualblocks code fullscreen",
                                                "insertdatetime table paste imagetools wordcount"
                                            ],
                                            toolbar: " myCustomToolbarButton, customInsertButton | insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link",
                                            file_picker_types: 'image',
                                            extended_valid_elements : "iframe[src|frameborder|style|scrolling|class|width|height|name|align]",
                                            file_picker_callback: function (cb, value, meta) {

                                                let div = document.getElementById("div");
                                                const divChild = div.childNodes[0];
                                                if(divChild){
                                                    document.getElementById("div").removeChild(divChild);
                                                }
                                                document.getElementById("modal").classList.remove("d-none");
                                                let imagesArray='';
                                                let card = document.createElement("div");
                                                card.classList.add("row");
                                                try {
                                                    axios.get(statick.serverUrl+"/api/images").then(images=>{
                                                        imagesArray = images.data;
                                                        for(let i = 0; i < imagesArray.length; i++){
                                                            let cardBody = document.createElement("div");
                                                            cardBody.classList.add("col-md-2");
                                                            let img = document.createElement("img");
                                                            let src = statick.serverUrl+"/api/images/"+imagesArray[i].id;
                                                            img.setAttribute("src", src);
                                                            img.setAttribute("id", imagesArray[i].id);
                                                            img.classList.add("card-img-top","m-2");
                                                            cardBody.appendChild(img);
                                                            card.appendChild(cardBody);
                                                            div.appendChild(card);
                                                            img.onclick=function (e) {
                                                                cb(statick.serverUrl+"/api/images/"+e.target.id,{title: "Salom!"});
                                                                document.getElementById("modal").classList.add("d-none");
                                                                document.getElementById("div").removeChild(div.childNodes[0]);
                                                            }
                                                        }

                                                    }).catch(e=>{
                                                        console.log(e);
                                                        toast.error("Rasm qo'shish bilan muamo bor!")
                                                    });
                                                }catch (e){
                                                    console.log(e)
                                                }
                                                let input = document.createElement('input');
                                                let buttonImg = document.createElement("div");
                                                let label =document.createElement("label");
                                                let textBtn = document.createTextNode("Rasmni kompyuterdan yuklash");
                                                label.setAttribute("for","file");
                                                label.classList.add("btn","btn-info");
                                                label.appendChild(textBtn);
                                                input.setAttribute('type', 'file');
                                                input.setAttribute('id', 'file');
                                                input.setAttribute('accept', 'image/*');
                                                input.setAttribute('hidden', 'hidden');
                                                input.onchange = function () {
                                                    const formData = new FormData();
                                                    const imagefile = this.files[0];
                                                    formData.append("images", imagefile);
                                                    try {
                                                        axios.post(statick.serverUrl+"/api/images",formData,{
                                                            headers: {
                                                                'Content-Type': 'multipart/form-data',
                                                                Authorization: localStorage.getItem('token'), //the token is a variable which holds the token
                                                            }
                                                        }).then(images=>{
                                                            const imageUrl = images.data;
                                                            cb(statick.serverUrl+"/api/images/"+imageUrl,{title: "Salom!"});
                                                            document.getElementById("modal").classList.add("d-none");
                                                        }).catch(e=>{
                                                            const status = e.response.status;
                                                            if(status === 401){
                                                                toast.error("Iltimos tizimga qayta kiring!");
                                                                setTimeout(()=>{
                                                                    localStorage.clear();
                                                                    this.setState({
                                                                        statusToken: false
                                                                    })
                                                                },1000)
                                                            }else{
                                                                toast.error("Rasm qo'shish bilan muamo bor!")
                                                            }
                                                        });
                                                    }catch (e){
                                                        console.log(e)
                                                    }
                                                };
                                                buttonImg.appendChild(input);
                                                buttonImg.appendChild(label);
                                                let child = document.getElementById("footer").hasChildNodes();
                                                if(!child){
                                                    document.getElementById("footer").appendChild(buttonImg);
                                                }

                                            }
                                        }}
                                        onChange={this.handleChange}/>
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>
                    <Row>
                        <Col md="4">
                            <AvField type="select" required name="parentId" lable="Journalni tanlang">
                                <option value="">Journalni tanalang</option>
                                {
                                    this.state.journal.map((item,i)=>{
                                        return(
                                            <option key={i} value={item.journal.id}>{item.journal.titleUz}</option>
                                        )
                                    })
                                }
                            </AvField>
                        </Col>
                    </Row>
                    <Button color="primary">Saqlash</Button>
                    {
                        !this.state.update ? <div className="ml-2 btn btn-outline-danger" onClick={this.props.setStatus}>Bekor qilish</div>:""
                    }
                </AvForm>


            </div>
        );
    }
}

AddDescription.propTypes = {
    setStatus: PropTypes.func,
};

export default AddDescription;
