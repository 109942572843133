import React, {Component} from 'react';
import {Button, Col, Row} from "reactstrap";
import AddDescription from "../components/addDesc";
import AllDescription from "../components/allDesc";

class Description extends Component {
    constructor(props) {
        super(props);
        this.state={
            status:true
        }
    }
    setStatus=()=>{
        this.setState({
            status:!this.state.status
        })
    }
    render() {
        return (
            <div>
                <Row className="py-4">
                    <Col md="12" className="text-right">
                        <Button color="info" className="py-0 px-4" onClick={this.setStatus}>
                            {this.state.status?<small> Sahifa yaratish </small>:<small> Bekor qilish</small>}
                        </Button>
                    </Col>
                </Row>
                {
                    this.state.status ? <AllDescription /> : <AddDescription update={true}/>
                }
            </div>
        );
    }
}

Description.propTypes = {};

export default Description;
