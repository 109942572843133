import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Container, Row} from "reactstrap";
import axios from "axios";
import "./index.scss";
import {Link, Redirect} from "react-router-dom";
import './index.scss'
//COMPONENTS
import DashboardBlock from "./components"
import statick from "../../const/index";
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";



class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumb: "Dashboard",
            block: "article",
            token: localStorage.getItem("token"),
            statusToken: true
        };
    }
    setBreadcrumb = (e)=>{
        this.setState({
            breadcrumb:e.target.innerText,
            block:e.target.getAttribute('title')
        })

    };
    signOut=()=>{
        localStorage.clear();
        this.setState({
            token: localStorage.getItem("token")
        })
    }
    componentDidMount(){
        const token = {
            token: this.state.token
        }
        try{
            axios.post(statick.serverUrl+"/api/user",token,{
                headers: {
                    Authorization: this.state.token //the token is a variable which holds the token
                }
            })
                .catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }
            })

        }catch (e){
            console.log(e)
        }
    }
    render() {
        const {block} = this.state;
        if(this.state.token && this.state.statusToken){
            return (
                <div className="dashboard">
                    <ToastContainer/>
                    <div className="dashboard-left">
                        <Link to="/dashboard">
                            <h5 className="text-center text-decoration-none py-4 border-bottom">DASHBOARD</h5>
                        </Link>
                        <Row>
                            <Col md="12" className="text-center py-3">
                                <div className="user-avatar">
                                    <span className="icon icon-user"/>
                                </div>
                                <p className="m-0 py-2">Administrator</p>
                                <Button color="info" className="small py-0 px-2" onClick={this.signOut}>Chiqish</Button>
                            </Col>
                        </Row>
                        <div className="py-3 dashboard-menu">
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <Button color="link" className="text-white btn-block text-left p-0" title="article" onClick={this.setBreadcrumb}>
                                        Maqolalar
                                    </Button>
                                </li>
                                <li className="list-group-item">
                                    <Button color="link" className="text-white btn-block text-left p-0" title="pages" onClick={this.setBreadcrumb}>
                                        Sahifa yaratish
                                    </Button>
                                </li>
                                <li className="list-group-item">
                                    <Button color="link" className="text-white btn-block text-left p-0" title="video" onClick={this.setBreadcrumb}>
                                        Video galereya
                                    </Button
                                    ></li>
                                <li className="list-group-item">
                                    <Button color="link" className="text-white btn-block text-left p-0" title="photo" onClick={this.setBreadcrumb}>
                                        Foto galereya
                                    </Button
                                    ></li>
                                <li className="list-group-item">
                                    <Button color="link" className="text-white btn-block text-left p-0" title="repertoire" onClick={this.setBreadcrumb}>
                                        Repertuarlar
                                    </Button>
                                </li>
                                <li className="list-group-item">
                                    <Button color="link" className="text-white btn-block text-left p-0" title="feedback" onClick={this.setBreadcrumb}>
                                        Qayt aloqa
                                    </Button>
                                </li>
                                <li className="list-group-item">
                                    <Button color="link" className="text-white btn-block text-left p-0" title="questions" onClick={this.setBreadcrumb}>
                                        Eng ko'p beriladigan savollar
                                    </Button>
                                </li>
                                <li className="list-group-item">
                                    <Button color="link" className="text-white btn-block text-left p-0" title="journal" onClick={this.setBreadcrumb}>
                                        Elektron jurnallar
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="dashboard-right">
                        <Row className="py-4">
                            <Col md="12">
                                <Link to="/dashboard" className="small text-decoration-none text-muted">{this.state.breadcrumb}</Link>
                            </Col>
                        </Row>
                        <main className="dashboard-main">
                            <DashboardBlock tag={this.state.block}/>
                        </main>
                    </div>
                </div>
            );
        }else{
            return(
                <Redirect to="/login"/>
            )
        }


    }
}

Dashboard.propTypes = {};

export default Dashboard;
