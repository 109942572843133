import React, {Component} from 'react';
import {Button, Col, Row} from "reactstrap";
import Description from "./description/index";
import Journals from "./journal/index";
class Journal extends Component {
    constructor(props){
        super(props);
        this.state={
            status:false
        }
    }
    setStatus=(status)=>{
        this.setState({
            status: status
        })
    }
    render() {
        return (
            <div className="dsh-journal">
                <Row>
                    <Col md="12">
                        <Button onClick={()=>this.setStatus(false)} color="outline-info" className={"mr-2 "+(!this.state.status ? "active":"")}>
                            Journal
                        </Button>
                        <Button onClick={()=>this.setStatus(true)} color="outline-info" className={"mr-2 "+(this.state.status ? "active":"")}>
                            Sahifalar
                        </Button>
                    </Col>
                </Row>
                {
                    !this.state.status ? <Journals/> : <Description/>
                }
            </div>
        );
    }
}

Journal.propTypes = {};

export default Journal;
