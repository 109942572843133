import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import statick from "../../../../const/index";
import {ToastContainer,toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";


class VideoList extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit= this.handleSubmit.bind(this);
        this.state = {
            token: localStorage.getItem('token'),
            statusToken: true,
            allVideo:[],
            oneVideo:[],
            status:true,
            imageId:"",
            selectImage:''
        }
    }
    componentDidMount(){
        try{
            axios.get(statick.serverUrl+"/api/video")
                .then(videos=>{
                    this.setState({
                        allVideo: videos.data,
                    })
                }).catch(e=>{
                console.log(e);
            });
        }catch (e){
            toast.error("Rasm yuklash bilan hatolik yuz berdi!")
        }
    };
    handleSubmit(e,error,value){
        const id = this.state.oneVideo.id;
        if(value.title && value.lang && value.description && value.images && value.videoUrl){
            try{
                axios.put(statick.serverUrl+"/api/video/"+id,value,{
                    headers: {
                        Authorization: this.state.token //the token is a variable which holds the token
                    }
                })
                    .then(video=>{
                        this.setState({
                            gallery:!this.state.gallery,
                            allVideo:video.data,
                            status:!this.state.status
                        });
                        toast.success("O'zgartirildi!");
                    }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Videoni yuklashda hatolik bor!");
                    }

                });
            }catch (e){
                toast.error("Rasm yuklash bilan hatolik yuz berdi!")
            }
        }else{
            toast.error("Iltimos ma'lumotlarni to'liq to'ldiring")
        }
    }
    Delete = (id)=>{
        try{
            axios.delete(statick.serverUrl+"/api/video/"+id,{
                headers: {
                    Authorization: this.state.token //the token is a variable which holds the token
                }
            })
                .then(videos=>{
                    this.setState({
                        allVideo: videos.data
                    })
                    toast.success("Video o'chirildi!")
                }).catch(e=>{
                const status = e.response.status;
                if(status === 401){
                    toast.error("Iltimos tizimga qayta kiring!");
                    setTimeout(()=>{
                        localStorage.clear();
                        this.setState({
                            statusToken: false
                        })
                    },1000)
                }else{
                    toast.error("Videoni yuklashda hatolik bor!");
                }
            });
        }catch (e){
            toast.error("Rasm yuklash bilan hatolik yuz berdi!")
        }
    };
    Update = (id)=>{
        try{
            axios.get(statick.serverUrl+"/api/video/"+id)
                .then(videos=>{
                    this.setState({
                        oneVideo: videos.data,
                        status:!this.state.status,
                        imageId: videos.data.imageUrl
                    })
                }).catch(e=>{
                console.log(e);
            });
        }catch (e){
            toast.error("Rasm yuklash bilan hatolik yuz berdi!")
        }
    };
    changePhoto =(e)=>{
        this.setState({
            selectImage:e.target.files[0]
        })
    };
    setImage =()=>{
        if(this.state.selectImage){
            const formData = new FormData();
            const imagefile = this.state.selectImage;
            formData.append("images", imagefile);
            try {
                axios.post(statick.serverUrl+"/api/attachment",formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: this.state.token //the token is a variable which holds the token
                    }
                }).then(images=>{
                    this.setState({
                        imageId:images.data[0].imageUrl
                    });
                    toast.success("Rasm qo'shildi!")
                }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }

                });
            }catch (e){
                console.log(e)
            }
        }
    };
    changeStatus=()=>{
        this.setState({
            status:!this.state.status,
            oneVideo:[],
            imageId:"",
            selectImage:''
        })
    }
    render() {
        return (
            <div className="dsh-add-video">
                <ToastContainer/>
                <Card>
                    <CardBody>
                        {this.state.allVideo.length===0?<h4 className="mb-0 text-center text-muted">Videolar mavjud emas!</h4>:
                            this.state.status ?
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>T/R</th>
                                    <th>Sarlavha</th>
                                    <th>Qisqacha ma'lumot</th>
                                    <th>Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.allVideo.map((item,i)=>{
                                        return(
                                            <tr key={item.id}>
                                                <td>{i+1}</td>
                                                <td>{item.title}</td>
                                                <td>{item.description}</td>
                                                <td><Button color="outline-info" className="smile mr-2" onClick={()=>this.Update(item.id)}>Taxrirlash</Button>
                                                    <Button color="outline-danger" className="smile" onClick={()=>this.Delete(item.id)}>&times;</Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>:
                                <AvForm onSubmit={this.handleSubmit} model={this.state.oneVideo}>
                                    <CardBody>
                                        <Row>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <AvField name="title" label="Sarlavha" required />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <AvField type="select" required name="lang" label="Maqola tili">
                                                        <option disabled={true} value="">Tilni tanlang</option>
                                                        <option value="uz">Uz</option>
                                                        <option value="ru">Ru</option>
                                                        <option value="eng">Eng</option>
                                                    </AvField>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="form-group">
                                                    <AvField type="textarea" name="description" label="Qisqacha mazmuni" required />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <label htmlFor="file" className="mr-2">
                                                    <span className="badge badge-info d-inline-block py-2 px-3">Video uchun rasm yuklang</span>
                                                </label>
                                                <input type="file" accept="image/png, image/jpeg, image/jpg" id="file" hidden={true} onChange={this.changePhoto}/>
                                                <span className="d-inline-block badge badge-success py-2 px-3" onClick={this.setImage}>Saqlash</span>
                                                <AvField required value={this.state.imageId} hidden name="images"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="form-group">
                                                    <AvField type="url" label="Video linkini kiriting" required name="videoUrl"/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12" className="text-right">
                                                <Button color="outline-danger" className="mr-2 small" onClick={this.changeStatus}>Bekor qilish</Button>
                                                <Button color="primary" className="small">Qo'shish</Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </AvForm>
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }
}

VideoList.propTypes = {};

export default VideoList;
