import React, {Component} from 'react';
import axios from "axios";
import statick from "../const/index";
import {Button, Card, CardDeck, Col, Container, Row} from "reactstrap";
import {ToastContainer,toast} from "react-toastify";
import {Link} from "react-router-dom";
import Loader from "../components/loader/loader";
class AllVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoId:1,
            singleVideo:"",
            allVideo:[],
            like:"",
            dislike:"",
            articleData:[],
            gallery:[],
            statickLang: this.props.statickLang,
            loader:true,
            languageVideo: false
        }
    }
    componentDidMount() {
        const videoId = this.state.videoId;
        axios.get(statick.serverUrl+"/api/video/"+videoId)
            .then(video=>{
                this.setState({
                    singleVideo : video.data,
                    like: video.data.like,
                    dislike: video.data.dislike
                });
            }).catch(e=>{
            console.log(e);
        });
        axios.get(statick.serverUrl+"/api/video")
            .then(video=>{
                this.setState({
                    allVideo : video.data
                });
            }).catch(e=>{
            console.log(e);
        });
        axios.get(statick.serverUrl+"/api/article")
            .then(article=>{
                this.setState({
                    articleData : article.data
                });
            }).catch(e=>{
            console.log(e);
        });
        axios.get(statick.serverUrl+"/api/gallery/all")
            .then(gallery=>{
                this.setState({
                    gallery : gallery.data
                });
            }).catch(e=>{
            console.log(e);
        })
        const timeLoader = setTimeout(()=>{
            this.setState({
                loader:false
            })
        },2000)
    }
    vote = (voteStatus)=>{
        axios.post(statick.serverUrl+"/api/video/"+this.state.videoId,{vote:voteStatus})
            .then(video=>{
                this.setState({
                    like: video.data.like,
                    dislike: video.data.dislike
                });
                toast.success("Ovoz berildi!",{
                    position: toast.POSITION.TOP_CENTER
                })

            }).catch(e=>{
            console.log(e);
        });
        axios.get(statick.serverUrl+"/api/video")
            .then(video=>{
                this.setState({
                    allVideo : video.data
                });
            }).catch(e=>{
            console.log(e);
        })

    };
    changeVideo =(id)=>{
        axios.get(statick.serverUrl+"/api/video/"+id)
            .then(video=>{
                this.setState({
                    videoId: id,
                    singleVideo : video.data,
                    like: video.data.like,
                    dislike: video.data.dislike
                });
            }).catch(e=>{
            console.log(e);
        });
    };
    render() {
        const statickLang = this.props.statickLang;
        const lang = this.props.lang;
        let numGalery =0;
        return (
            <div className="dsh-video-block">
                {this.state.loader? <Loader/>:""}
                <ToastContainer/>
                <Container className="py-4 bg-white">
                    <h4 className="m-0 text-muted">{statickLang.VIDEO}</h4>
                    <Row className="border-bottom border-light py-4">
                        <Col md="8">
                            {
                                this.state.singleVideo.lang == lang ?
                                    <div>
                                        <div contentEditable='false' dangerouslySetInnerHTML={{ __html: this.state.singleVideo.videoUrl }}/>
                                        <Row>
                                            <Col md="10">
                                                <h5 className="m-0 py-3 text-dark">{this.state.singleVideo.title}</h5>
                                            </Col>
                                            <Col md="2">
                                                <ul className="list-unstyled text-right py-3">
                                                    <li disabled={true} onClick={()=>this.vote(true)} className="d-inline-block text-muted small" title="Like">
                                                        <span className="icon icon-finger-up mr-1" />
                                                        {this.state.like}
                                                    </li>
                                                    <li onClick={()=>this.vote(false)} className="d-inline-block text-muted small ml-3" title="Dislike">
                                                        <span className="icon icon-finger-down mr-1"  />
                                                        {this.state.dislike}
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                        <p className="py-3 text-muted">
                                            {this.state.singleVideo.description}
                                        </p>
                                    </div>: <h1 className="text-muted text-center py-5">{statickLang.NOCONTENT}</h1>
                            }

                        </Col>
                        <Col md="4" className="bar">
                            <p href="#" className="text-uppercase mb-4 pl-0 text-color nav-link">{statickLang.VIDEO}</p>
                            <div className="video-bar">
                                {
                                    this.state.allVideo.map((item,i)=>{
                                        if(item.lang == lang){
                                            return(
                                                <Card key={i} className={"border-0 rounded-0 mb-4 "+ (this.state.singleVideo.id === item.id ? "video-play":"")}>
                                                    <Row>
                                                        <Col md="4">
                                                            <div className="position-relative">
                                                                <img src={
                                                                    item.imageUrl!==""? statick.serverUrl+"/"+item.imageUrl: "src/images/video-camera.svg"
                                                                } className="img-fluid" alt={item.title}/>
                                                                <div className="card-img-overlay">
                                                                    <Button onClick={()=>this.changeVideo(item.id)} color="link" className="text-decoration-none">
                                                                        <span className="icon icon-play" />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="8">
                                                            <h4 className="m-0">
                                                                <Button onClick={()=>this.changeVideo(item.id)} color="link" className="text-decoration-none small">
                                                                    {item.title}
                                                                </Button>
                                                            </h4>
                                                        </Col>
                                                    </Row>
                                                    <ul className="mt-1 mb-2 p-0">
                                                        <li className="d-inline-block text-muted pr-5">
                                                            {

                                                                item.updatedAt.substring(8,10)+
                                                                "."+item.updatedAt.substring(5,7)+
                                                                "."+item.updatedAt.substring(0,4)
                                                            }
                                                        </li>
                                                        <li className="d-inline-block text-muted small ml-5 pl-5">
                                                            <span className="icon icon-finger-up" />
                                                            {item.like}
                                                        </li>
                                                        <li className="d-inline-block text-muted small ml-2">
                                                            <span className="icon icon-finger-down" />
                                                            {item.dislike}
                                                        </li>
                                                    </ul>
                                                </Card>
                                            );
                                            this.setState({
                                                languageVideo: true
                                            })
                                        }
                                    })
                                }
                                {
                                    !this.state.languageVideo ? <h4 className="text-center text-muted">{statickLang.NOCONTENT}</h4>:""
                                }
                            </div>

                            <div className="gallery-block">
                                {this.state.gallery.length !== 0 && this.state.gallery[0].images.length!==0?
                                    <div>
                                        {
                                            this.state.gallery.map((item,i)=>{
                                                if(item.gallery.lang === this.props.lang){
                                                    numGalery++;
                                                    if(numGalery<=1){
                                                        return(
                                                            <Card key={i} className="border-0 rounded-0">
                                                                {item.gallery ?
                                                                    <Link to={{ pathname : '/gallery', state: { gallery : this.state.gallery, galleryId:item.gallery.id} }}>
                                                                        <img src={statick.serverUrl+"/"+item.images[0].imageUrl} className="img-fluid" alt={item.gallery.title}/>
                                                                    </Link>:""
                                                                }
                                                            </Card>
                                                        )
                                                    }else if(numGalery <=3){
                                                        return(
                                                            <CardDeck key={i} className="mt-3">
                                                                <Card className="border-0 rounded-0">
                                                                    {item ?
                                                                        <Link to={{ pathname : '/gallery', state: { gallery : this.state.gallery, galleryId:item.gallery.id} }}>
                                                                            <img src={statick.serverUrl+"/"+item.images[0].imageUrl} className="img-fluid" alt={item.gallery.title}/>
                                                                        </Link>:""
                                                                    }
                                                                </Card>
                                                            </CardDeck>
                                                        )
                                                    }
                                                }
                                            })
                                        }
                                        {
                                            numGalery === 0 ?
                                                <h4 className="text-center text-muted">
                                                    {statickLang.NOCONTENT}
                                                </h4>:""
                                        }


                                    </div>: <h4 className="text-center text-muted">Foro gallereya mavjud emas</h4>
                                }
                            </div>
                        </Col>
                    </Row>
                    <h4 className="py-4">{statickLang.LATESTNEWS}</h4>
                    <Row className="py-4 border-top border-light">
                        {
                            this.state.articleData.map((item,i)=>{
                                if(i<=3){
                                    if(lang===item.lang)
                                    return(
                                        <Col md="3" key={i}>
                                            <img src={statick.serverUrl+"/api/images/"+item.imagesUrl} className="img-fluid" alt={item.title} />
                                            <div className="px-2">
                                                <p className="m-0 small text-muted">
                                                    {

                                                        item.updatedAt.substring(8,10)+
                                                        "."+item.updatedAt.substring(5,7)+
                                                        "."+item.updatedAt.substring(0,4)
                                                    }
                                                </p>
                                                <h4 className="my-2 f-regulat">
                                                    <Link to={`/article/${item.id}`}>{item.title}</Link>
                                                </h4>
                                                <p className="f-light">
                                                    {item.description}
                                                </p>
                                            </div>
                                        </Col>
                                    )
                                }
                            })
                        }
                    </Row>
                </Container>
            </div>
        );
    }
}

AllVideo.propTypes = {};

export default AllVideo;
