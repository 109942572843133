import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import {Button, Col, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import statick from "../../../../const/index";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css";
import {Redirect} from "react-router-dom";

class QuestionsForm extends Component {
    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state={
            status:this.props.status,
            model:this.props.question,
            statusToken:true
        }
    }
    handleSubmit(event,error,value){
        if(error.lenght !==0 && !this.state.model){
            try{
                axios.post(statick.serverUrl+"/api/question",value,{
                    headers: {
                        Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                    }
                })
                    .then(question=>{
                        toast.success("Savol qo'shildi!");
                    }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }
                })

            }catch (e){
                console.log(e)
            }
        }else if(error.lenght !==0 && this.state.model){
            try{
                axios.put(statick.serverUrl+"/api/question/"+this.state.model.id ,value,{
                    headers: {
                        Authorization: localStorage.getItem('token') //the token is a variable which holds the token
                    }
                })
                    .then(question=>{
                        toast.success("Savol o'zgartirildi");
                    }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            localStorage.clear();
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }
                })

            }catch (e){
                console.log(e)
            }
        }
    }
    model ={
        leng:"",
        title:"",
        description:""
    }
    render() {
        if(this.state.statusToken){
            return (
                <div>
                    <ToastContainer/>
                    <AvForm onSubmit={this.handleSubmit} model={this.state.model?this.state.model:this.model}>
                        <Row>
                            <Col md="6">
                                <AvField type="text" required name="title" label="Sarlavha" placeholder="Savolni kiriting"/>
                            </Col>
                            <Col md="6">
                                <AvField type="select" required name="leng" label="Sarlavha">
                                    <option value="">Tilni tanlang</option>
                                    <option value="uz">Uz</option>
                                    <option value="ru">Ru</option>
                                    <option value="eng">Eng</option>
                                </AvField>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <AvField type="textarea" name="description" required placeholder="Javobni kiriting"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Button color="primary">Saqlash</Button>
                            </Col>

                        </Row>
                    </AvForm>

                </div>
            );
        }else{
            return (
                <Redirect to="/login"/>
                    );
        }

    }
}

QuestionsForm.propTypes = {};

export default QuestionsForm;
