const statick = {
    serverUrl: "",
    lang:{
        uz:{
            CONTACTS:{
                address:"100011, Toshkent shahar, Xadra maydoni 1-uy",
                tel: "(71) 244-35-09, (71) 244-34-31",
                mail: "sirk.uz@mail.ru"
            },
            REPERTOIRE:"Repertuar",
            READMORE:"Batafsil",
            PAGES: "Sahifalar",
            JOURNAL: "Elektron jurnal",
            ALLJOURNAL: "Barcha jurnallar",
            SEARCH_RESULT: "Qidiruv natijasi",
            LANG_NORESULT: "Tanlangan til bo'yicha ma'lumot topilmadi!",
            AGAIN:"Yana ko'rsatish",
            NOCONTENT:"Ma'lumot mavjud emas",
            TOHOME:"Bosh sahifaga qaytish",
            SIRK: "O'zbekiston Davlat sirki",
            ARTIST: "Sirk artistlari",
            TICKET: "Chiptalar",
            EVENTS: "Rejadagi tadbirlar",
            NEWS: "Yangiliklar",
            ANONS: "Anons",
            ALLNEWS: "Barcha yangiliklar",
            LATESTNEWS: "So'nggi yangiliklar",
            ALLVIDEO: "Barcha videolar",
            ALLPHOTO: "Barcha fotolar",
            VIDEO: "Video galereya",
            PHOTO: "Foto galereya",
            OTHERPHOTO: "Boshqa foto galereya",
            SEARCH: "Nima izlaymiz...",
            FEEDBACK: "Takliflar va qayta aloqa",
            FEEDBACK_2: "SHIKOYAT VA TAKLIFLAR",
            FEEDBACK_INPUT: {
                name: "Ism, Familiya, Otasining ismi",
                email:"Aloqa uchun email yoki telefon raqamingiz",
                desc: "Fikr - mulohaza, shikoyatlaringizni kiriting"
            },
            FORM_BTN: "Yuborish",
            QUESTION:{
              TITLE: "Eng ko'p beriladigan savollar",
              BTN: "Barcha savollarni ko'rish"
            },
            VOTE:{
                TITLE:"Sayt so'rovnomasi",
                BTN:"Ovoz berish",
                RESULT: "Ovoz berish natijasi",
                MESSAGE_SUCCESS:"Ovoz berildi",
                QUESTION: "Bizning sayt sizga ma'qulmi?",
                YES: "Ha",
                NO:"Yo'q",
                INDIFFERENT:"Men befarqman"
            },

        },
        ru:{
            CONTACTS:{
                address:"100011, г. Ташкент, ул. площадь Хадра, 1",
                tel: "(71) 244-35-09, (71) 244-34-31",
                mail: "sirk.uz@mail.ru"
            },
            REPERTOIRE:"Репертуар",
            READMORE:"Подробно",
            PAGES: "Cтраницы",
            JOURNAL: "Электронный журнал",
            ALLJOURNAL: "Все журналы",
            SEARCH_RESULT: "Результат поиска",
            LANG_NORESULT: "Информация на выбранном языке не найдено",
            AGAIN:"Показать больше",
            NOCONTENT:"Нет информации",
            TOHOME:"Вернуться на главную страницу",
            SIRK:"Государственный Цирк Узбекистана",
            ARTIST: "Артисты цирка",
            TICKET: "Билеты",
            EVENTS: "Запланированные события",
            NEWS: "Новости",
            ANONS: "Анонсы",
            ALLNEWS: "Все новости",
            LATESTNEWS: "Последние новости",
            ALLVIDEO: "Все видео",
            ALLPHOTO: "Все фотографии",
            VIDEO: "Видео галерея",
            PHOTO: "Фото галерея",
            OTHERPHOTO: "Другие фотогалереи",
            SEARCH: "Что вы ищете?",
            FEEDBACK: "Предложения и отзывы",
            FEEDBACK_2: "ОТЗЫВЫ И ПРЕДЛОЖЕНИЯ",
            FEEDBACK_INPUT: {
                name: "Имя, Фамилия, Отчество",
                email:"Контактный адрес электронной почты или телефон номер",
                desc: "Напишите свой отзыв и комментарий"
            },
            FORM_BTN: "Переслать",
            QUESTION:{
                TITLE: "Наиболее задаваемые вопросы",
                BTN: "Посмотреть все вопросы"
            },
            VOTE:{
                TITLE: "Опрос сайта",
                BTN: "Голосование",
                RESULT: "Результаты голосования",
                MESSAGE_SUCCESS: "Проголосовано",
                QUESTION: "Довольны ли вы нашим сайтом?",
                YES: "Да",
                NO: "Нет",
                INDIFFERENT:"Я равнодушен"
            }
        },
        eng:{
            CONTACTS:{
                address:"100011, Tashkent city, Khadra square str., 1",
                tel: "(71) 244-35-09, (71) 244-34-31",
                mail: "sirk.uz@mail.ru"
            },
            REPERTOIRE:"Repertoire",
            READMORE: "Read more",
            PAGES: "Pages",
            JOURNAL: "Electronic journal",
            ALLJOURNAL: "All journals",
            SEARCH_RESULT: "Search result",
            LANG_NORESULT: "The selected language information is unavailable",
            AGAIN: "Show more",
            NOCONTENT: "No data available",
            TOHOME: "Return to main page",
            SIRK: "State Circus of Uzbekistan",
            ARTIST: "Circus artists",
            TICKET: "Tickets",
            EVENTS: "Planned events",
            NEWS: "News",
            ANONS: "Anons",
            ALLNEWS: "All news",
            LATESTNEWS: "Latest news",
            ALLVIDEO: "All video",
            ALLPHOTO: "All photo",
            VIDEO: "Video gallery",
            PHOTO: "Photo gallery",
            OTHERPHOTO: "Other photo gallery",
            SEARCH: "What are you looking for?",
            FEEDBACK: "CHALLENGES AND SUGGESTIONS",
            FEEDBACK_INPUT: {
                name: "Full name",
                email: "Contact email or phone number",
                desc: "Write your review and comments"
            },
            FORM_BTN: "Send",
            QUESTION:{
                TITLE: "Most asked questions",
                BTN: "See all questions"
            },
            VOTE: {
                TITLE: "Site survey",
                BTN: "Vote",
                RESULT: "Voting results",
                MESSAGE_SUCCESS: "Voted",
                QUESTION: "Are you satisfied with our site?",
                YES: "Yes",
                NO: "No",
                INDIFFERENT: "I am indifferent"
            }

        }
    }
}
export default statick;