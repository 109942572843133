import React, {Component} from 'react';
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import axios from "axios";
import statick from "../../../../const/index";
import {ToastContainer,toast} from "react-toastify";
import {Redirect} from "react-router-dom";
class AddFeedback extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            model:{},
            status:true,
            image:"",
            imageUrl:this.props.model.imageUrl,
            feedbackStatus: true,
            statusToken: true,
            token: localStorage.getItem('token'),
        };
    }
    setImage=(e)=>{
        this.setState({
            image: e.target.files[0]
        })
    };
    addImage=()=>{
        if(this.state.image){
            const formData = new FormData();
            const imagefile = this.state.image;
            formData.append("images", imagefile);
            try {
                axios.post(statick.serverUrl+"/api/attachment",formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: this.state.token //the token is a variable which holds the token
                    }
                }).then(images=>{
                    this.setState({
                        imageUrl:images.data[0].imageUrl,
                        image:''
                    });
                    toast.success("Rasm qo'shildi!")
                }).catch(e=>{
                    const status = e.response.status;
                    if(status === 401){
                        toast.error("Iltimos tizimga qayta kiring!");
                        setTimeout(()=>{
                            this.setState({
                                statusToken: false
                            })
                        },1000)
                    }else{
                        toast.error("Rasm qo'shish bilan muamo bor!")
                    }
                });
            }catch (e){
                console.log(e)
            }
        }
    };
    handleSubmit(event,error,value){

        if(!this.props.update && error.length===0){
            try{
                axios.post(statick.serverUrl+"/api/feedback",value,{
                    headers: {
                        Authorization: this.state.token //the token is a variable which holds the token
                    }
                })
                    .then(feed=>{
                        if(feed.data.length !==0){
                            toast.success("Qo'shildi!");
                        }
                    }).catch(e=>{
                    console.log(e)
                })
            }catch (e){
                console.log(e)
            }
        }else if(this.props.update && error.length ===0){
            try{
                axios.put(statick.serverUrl+"/api/feedback/"+this.props.model.id,value,{
                    headers: {
                        Authorization: this.state.token //the token is a variable which holds the token
                    }
                })
                    .then(feed=>{
                        if(feed.data.length !==0){
                            toast.success("O'zgartirildi!")
                        }
                    }).catch(e=>{
                    console.log(e)
                })
            }catch (e){
                console.log(e)
            }
        }
    }
    render() {
        const model={
            userName:"",
            userRegion: "",
            leng: "",
            description: "",
            images:""
        }
        if(this.state.statusToken){
            return (
                <div>
                    <ToastContainer/>
                    <AvForm onSubmit={this.handleSubmit} model={this.props.update ? this.props.model : model} className="py-4">
                        <Row>
                            <Col md="4">
                                <AvField type="text" required name="userName" label="Ism sharifi" placeholder="Ism sharifi"/>
                            </Col>
                            <Col md="4">
                                <AvField type="text" required name="userRegion" label="Region" placeholder="Region"/>
                            </Col>
                            <Col md="4">
                                <AvField type="select" required name="leng" label="Tilni tanlang">
                                    <option value="">Tilni tanlang</option>
                                    <option value="uz">Uz</option>
                                    <option value="ru">Ru</option>
                                    <option value="eng">Eng</option>
                                </AvField>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <AvField type="textarea" required name="description" label="Qisqacha mazmuni" placeholder="Qisqacha mazmuni"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <input type="file" id="changeImage" onChange={this.setImage} hidden={true}/>
                                <label htmlFor="changeImage" className="btn-block">
                                    <span className="btn badge-info text-white btn-block">Rasm yuklash</span>
                                </label>
                                <AvField type="text" name="images" value={this.state.imageUrl} required hidden={true}/>
                            </Col>
                            <Col md="3">
                                <Button color="primary" onClick={this.addImage}>Rasmni saqlash</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Button color="primary">Saqlash</Button>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            );
        }else{
            return (
                <Redirect to="/login"/>
            );
        }

    }
}

AddFeedback.propTypes = {};

export default AddFeedback;
