import React, {Component} from 'react';
import axios from "axios"
import statick from "../../const/index";
import {ToastContainer, toast} from "react-toastify";
import {Col, Container, Row} from "reactstrap";
import Bar from "../../components/bar/index"
import Loader from "../../components/loader/loader";

class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: "",
            desc: "",
            loader: true
        }
    }

    componentDidMount() {
        try {
            axios.get(statick.serverUrl + "/api/page/" + 5)
                .then(page => {
                    this.setState({
                        pages: page.data
                    });
                }).catch(e => {
                console.log(e);
            });
            axios.get(statick.serverUrl + "/api/descriptions/" + 5)
                .then(desc => {
                    this.setState({
                        desc: desc.data
                    });
                }).catch(e => {
                console.log(e);
            });

        } catch (e) {
            toast.error("Ma'lumot yuklash bilan bog'liq hatolik bor!", {
                position: toast.POSITION.TOP_CENTER
            })
        }
        const timeLoader = setTimeout(() => {
            this.setState({
                loader: false
            })
        }, 2000)
    }

    title = (status) => {
        if (status) {
            if (this.props.lang === "uz") {
                return (
                    <span>{this.state.pages.titleUz}</span>
                )
            } else if (this.props.lang === "ru") {
                return (
                    <span>{this.state.pages.titleRu}</span>
                )
            } else if (this.props.lang === "eng") {
                return (
                    <span>{this.state.pages.titleEng}</span>
                )
            }
        } else {
            if (this.props.lang === "uz") {
                return (
                    <div className="text-justify py-2 py-md-4" contentEditable='false'
                         dangerouslySetInnerHTML={{__html: this.state.desc.descriptionUz}}/>
                )
            } else if (this.props.lang === "ru") {
                return (
                    <div className="text-justify py-2 py-md-4" contentEditable='false'
                         dangerouslySetInnerHTML={{__html: this.state.desc.descriptionRu}}/>
                )
            } else if (this.props.lang === "eng") {
                return (
                    <div className="text-justify py-2 py-md-4" contentEditable='false'
                         dangerouslySetInnerHTML={{__html: this.state.desc.descriptionEng}}/>
                )
            }
        }
    }

    render() {
        return (
            <div>
                {this.state.loader ? <Loader/> : ""}
                <Container className="py-5 bg-white">
                    <Row>
                        <Col md="9">
                            <h6 className="text-muted mb-0 px-4"> {this.title(true)}</h6>
                            <div className="px-3 px-md-5">
                                {
                                    this.title(false)
                                }
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47942.273602656715!2d69.21113787910157!3d41.322086399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x57144c7c05319162!2z0KLQsNGI0LrQtdC90YLRgdC60LjQuSDRhtC40YDQug!5e0!3m2!1sru!2s!4v1557302030901!5m2!1sru!2s"
                                    width="100%" height="450" frameborder="0" style={{border: 0}} allowfullscreen></iframe>
                            </div>
                        </Col>
                        <Col md="3">
                            <Bar lang={this.props.lang} statickLang={this.props.statikLang}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Contacts.propTypes = {};

export default Contacts;
