import React, {Component} from 'react';
import statick from "../../const/index";
import axios from "axios"
import Header from "../../components/header/index";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import Bar from "../../components/bar/index";
import {Link} from "react-router-dom";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state={
            searchtText: this.props.search,
            status:true,
            result:[]
        }

    }
    componentDidMount(){
        axios.post(statick.serverUrl+"/api/search",  {
            text: this.props.search
        }).then(e=>{
            this.setState({
                result: e.data
            })
        })
    }
    setSearchText =(event)=>{
        this.setState({
            searchtText: event.target.value
        })
    };
    setStatus = ()=>{
       if(this.state.searchtText && this.state.searchtText!==''){

           axios.post(statick.serverUrl+"/api/search",  {
               text: this.state.searchtText
           }).then(e=>{
               console.log(e)
              this.setState({
                  result: e.data
              })
           })
       }
    };
    render() {
        const lang = this.props.lang;
        let statickLang=this.props.statickLang;
        return (
            <div>
                <Header eye={this.eye} setStatus={this.setStatus} status={this.state.status} setSearchText={this.setSearchText} lang={lang} statikLang = {statick.lang}  setlang={this.props.setlang}/>
                <Container className="bg-white py-4">
                    <Row>
                        <Col md="9">
                            <div className="px-4">
                                <h6>{statickLang.SEARCH_RESULT}</h6>
                                {this.state.result.length!==0 ?

                                    <div>
                                        {this.state.result.map((item,i)=>{
                                            if(item.result[0][0].lang === lang){
                                                return(
                                                    <Card key={i} className="border-bottom border-left-0 border-right-0 border-top-0 border-light">
                                                        <CardBody>
                                                            <h6 className="text-muted">{
                                                                item.category ==="news" ? statickLang.NEWS :
                                                                    item.category ==="galleries" ? statickLang.PHOTO : ""
                                                            }</h6>
                                                        </CardBody>
                                                        {item.category ==="news" ?
                                                            item.result[0].map((itemResult,i)=>{
                                                                return(
                                                                    <CardBody key={i}>
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <Link to={{ pathname : '/article', state: { articleId : itemResult.id, statickLang: statickLang, lang:lang } }}>{itemResult.title}</Link>
                                                                                <p className="m-0 py-4 small text-muted">
                                                                                    {

                                                                                        itemResult.updatedAt.substring(8,10)+
                                                                                        "."+itemResult.updatedAt.substring(5,7)+
                                                                                        "."+itemResult.updatedAt.substring(0,4)
                                                                                    }
                                                                                </p>
                                                                                <p className="py-4 f-light">
                                                                                    {itemResult.description}
                                                                                </p>
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                )
                                                            }) :

                                                            item.category ==="galleries" ?
                                                                item.result[0].map((itemResult,i)=>{
                                                                    return(
                                                                        <CardBody key={i}>
                                                                            <Row>
                                                                                <Col md="12">
                                                                                    <Link to={{ pathname : '/gallery', state: { gallery : this.state.gallery, galleryId:itemResult.id} }}>{itemResult.title}</Link>
                                                                                    <p className="m-0 py-4 small text-muted">
                                                                                        {

                                                                                            itemResult.updatedAt.substring(8,10)+
                                                                                            "."+itemResult.updatedAt.substring(5,7)+
                                                                                            "."+itemResult.updatedAt.substring(0,4)
                                                                                        }
                                                                                    </p>
                                                                                    <p className="py-4 f-light">
                                                                                        {itemResult.description}
                                                                                    </p>
                                                                                </Col>
                                                                            </Row>
                                                                        </CardBody>
                                                                    )
                                                                }):
                                                                ""
                                                        }
                                                    </Card>
                                                )
                                            }else{
                                                return(
                                                    <h4 key={i} className="text-center text-muted py-4">
                                                        {statickLang.LANG_NORESULT}
                                                    </h4>
                                                )
                                            }
                                        })}
                                    </div>

                                    : <h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>

                                }
                            </div>
                        </Col>
                        <Col md="3">
                            <Bar lang={this.props.lang} statickLang={this.props.statickLang}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Search.propTypes = {};

export default Search;
