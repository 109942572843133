import React, {Component} from 'react';
import './index.scss';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css"
import * as axios  from 'axios';
import {
    Button,
    Card, CardBody, CardDeck, CardGroup, Col, Container, Nav, NavItem, NavLink, Row, TabContent,
    TabPane
} from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Bar from "../../components/bar/index";
import {AvField, AvForm} from "availity-reactstrap-validation";
import statick from "../../const/index";
import {Link} from "react-router-dom";
import Anons from "../pages/anons";
import Loader from "../../components/loader/loader";

class Home extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            articleData: [],
            repertoire: [],
            lang: this.props.lang,
            voting: [],
            progressW: 0,
            votingActive: "",
            feedback: [],
            questions:[],
            loader:true,
            journal: []
        };
    }
    componentDidMount() {
        axios.get(statick.serverUrl+"/api/article")
            .then(article=>{
                this.setState({
                    articleData : article.data
                });
            }).catch(e=>{
            console.log(e);
        });
        axios.get(statick.serverUrl+"/api/repertoire")
            .then(rep=>{
                this.setState({
                    repertoire : rep.data
                });
            }).catch(e=>{
            console.log(e);
        });
        axios.get(statick.serverUrl+"/api/feedback")
            .then(feed=>{
                this.setState({
                    feedback : feed.data
                });
            }).catch(e=>{
            console.log(e);
        });
        axios.get(statick.serverUrl+"/api/question")
            .then(questions=>{
                this.setState({
                    questions : questions.data
                });
            }).catch(e=>{
            console.log(e);
        });
        axios.get(statick.serverUrl+"/api/voting")
            .then(voting=>{
                const summa = voting.data[0].indifferent + voting.data[0].no + voting.data[0].yes;
                this.setState({
                    voting : {
                        summa: summa,
                        yes: parseInt((voting.data[0].yes * 100) / summa),
                        indifferent: parseInt((voting.data[0].indifferent * 100) / summa),
                        no: parseInt((voting.data[0].no * 100)/summa),
                    },
                });
            }).catch(e=>{
            console.log(e);
        });
        axios.get(statick.serverUrl+"/api/journal")
            .then(journal=>{
                this.setState({
                    journal: journal.data
                });
            }).catch(e=>{
            console.log(e);
        });
        const timeLoader = setTimeout(()=>{
            this.setState({
                loader:false
            })
        },2000)
    }
    handleSubmit(event,error,values){
        if(values.userName && values.userContacts && values.description){
            axios.patch(statick.serverUrl+"/api/feedback/about", values)
                .then(article=>{
                    if(article.data.id){
                        toast.success("Habar jo'natildi!", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        this.form && this.form.reset();
                    }else{
                        toast.error("Habar jo'natishda muammo mavjud! Keyinroq urinib ko'ring", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                }).catch(e=>{
                console.log(e);
            })
        }
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    changProgress=(e)=>{
        const type = e.target.getAttribute("id");
        if(type==="yes"){
            this.setState({
                progressW: this.state.voting.yes,
                votingActive:type
            })
        }else if(type==="no"){
            this.setState({
                progressW: this.state.voting.no,
                votingActive:type
            })
        }else if(type ==="indifferent"){
            this.setState({
                progressW: this.state.voting.indifferent,
                votingActive:type
            })
        }
    }
    votingSubmit=()=>{
        const value = {
            vType:this.state.votingActive
        }
        if(value.vType !==""){
            try {
                axios.put(statick.serverUrl+"/api/voting", value)
                    .then(voting=>{
                        const summa = voting.data.indifferent + voting.data.no + voting.data.yes;
                        this.setState({
                            voting : {
                                summa: summa,
                                yes: parseInt((voting.data.yes * 100) / summa),
                                indifferent: parseInt((voting.data.indifferent * 100) / summa),
                                no: parseInt((voting.data.no * 100)/summa),
                            },
                        });
                        this.setState({
                            progressW : value.vType ==="yes" ? this.state.voting.yes : value.vType ==="no" ? this.state.voting.no : this.state.voting.indifferent
                        });

                    }).catch(e=>{
                    console.log(e);
                })
            }catch (e){
                console.log(e);
            }
        }
    }
    render() {
        const statickLang = this.props.statikLang;
        const lang = this.props.lang;
        let articleNum = 0;
        let questionNum = 0;
        return (
            <main>
                <ToastContainer/>
                {this.state.loader? <Loader/>:""}
                <section className="news">
                    <Container className="bg-white">
                        <Row className="py-4">
                            <Col md="9">
                                <Nav pills className="pl-3">
                                    <NavItem>
                                        <NavLink
                                            className={"text-uppercase f-bold " + (this.state.activeTab === '1' ? "active" :"")}
                                            onClick={() => { this.toggle('1'); }}
                                        >
                                            {statickLang.NEWS}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={"text-uppercase f-bold "+( this.state.activeTab === '2' ? "active" :"")}
                                            onClick={() => { this.toggle('2'); }}
                                        >
                                            {statickLang.ANONS}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="ml-auto">
                                        <Link to="/all-article" className={"nav-link text-uppercase"}>
                                            {statickLang.ALLNEWS}
                                        </Link>
                                    </NavItem>
                                </Nav>
                                <TabContent className="py-3" activeTab={this.state.activeTab}>
                                    <TabPane className="fade show" tabId="1">
                                        <Row className="py-2">

                                            {
                                                this.state.articleData.map((item,i)=>{
                                                    if(item.lang === lang && 6 > articleNum){
                                                       articleNum++;
                                                        return(
                                                            <Col md="4" key={i}>
                                                                <img src={statick.serverUrl+"/api/images/"+item.imagesUrl} className="img-fluid" alt={item.title} />
                                                                <div className="px-2">
                                                                    <p className="m-0 small text-muted">
                                                                        {

                                                                            item.updatedAt.substring(8,10)+
                                                                            "."+item.updatedAt.substring(5,7)+
                                                                            "."+item.updatedAt.substring(0,4)
                                                                        }
                                                                    </p>
                                                                    <h4 className="my-2 f-regulat">
                                                                        <Link to={`/article/${item.id}`}>{item.title}</Link>
                                                                    </h4>
                                                                    <p className="f-light">
                                                                        {item.description}
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                        )
                                                    }
                                            })
                                            }
                                            {
                                                articleNum ===0 ?
                                                        <Col md="12">
                                                            <h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>
                                                        </Col>
                                                    :""
                                            }
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2" className="pt-4">
                                        <Anons lang={lang} statickLang={statickLang}/>
                                    </TabPane>
                                </TabContent>
                            </Col>
                            <Col md="3">
                                <Bar lang={this.props.lang} statickLang={this.props.statikLang}/>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="repertoire">
                    <Container className="bg-white">
                        <Row>
                            <Col md="12">
                                <CardGroup>
                                    {
                                        this.state.repertoire.map((item,i)=>{
                                            if(item.lang===this.props.lang && i < 3){
                                                return(
                                                    <Card key={i} className="border-0 rounded-0">
                                                        <img src={statick.serverUrl+"/"+item.imageUrl} className="img-fluid" alt={item.title} />
                                                        <div className="card-img-overlay">
                                                            <h4 className="mb-0 text-color f-bold text-center text-uppercase">
                                                                {item.title}
                                                            </h4>
                                                        </div>
                                                        <Link to={{pathname:"/repertoire", state:{id:item.id}}} />
                                                    </Card>
                                                )
                                            }
                                        })
                                    }
                                </CardGroup>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="bg-white journal pb-5 border-bottom border-light">
                        <Row>
                            <Col md="12" className="py-5">
                                <h4 className="m-0 pt-4 text-color f-bold text-uppercase">{statickLang.JOURNAL}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Carousel className="bg-white" showArrows={false} showStatus={false} showThumbs={false} autoPlay stopOnHover infiniteLoop={true}>
                                    {
                                        this.state.journal.length !==0 ?
                                            this.state.journal.map((item,i)=>{
                                                if(lang === 'uz'){
                                                   return(
                                                       <Row key={i}>
                                                           <Col md="6">
                                                               <img src={statick.serverUrl+"/api/images/"+ item.journal.img} className="img-fluid" alt={item.journal.titleUz}/>
                                                           </Col>
                                                           <Col md="6">
                                                                <h4 className="text-left">
                                                                    <Link to={`/journal/${item.journal.id}`}>
                                                                        {item.journal.titleUz}
                                                                    </Link>
                                                                </h4>
                                                               {
                                                                   item.description.length !==0 ?
                                                                       <div className="journal-text mt-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: item.description[0].descriptionUz}}/> :
                                                                       <h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>
                                                               }
                                                           </Col>
                                                       </Row>
                                                   )
                                                }else if(lang === 'ru'){
                                                    return(
                                                        <Row key={i}>
                                                            <Col md="6">
                                                                <img src={statick.serverUrl+"/api/images/"+ item.journal.img} className="img-fluid" alt={item.journal.titleRu}/>
                                                            </Col>
                                                            <Col md="6">
                                                                <h4 className="text-left">
                                                                    <Link to={`/journal/${item.journal.id}`}>
                                                                        {item.journal.titleRu}
                                                                    </Link>
                                                                </h4>
                                                                {
                                                                    item.description.length !==0 ?
                                                                        <div className="journal-text mt-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: item.description[0].descriptionRu}}/> :
                                                                        <h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    )
                                                }else if(lang ==='eng'){
                                                    return(
                                                        <Row key={i}>
                                                            <Col md="6">
                                                                <img src={statick.serverUrl+"/api/images/"+ item.journal.img} className="img-fluid" alt={item.journal.titleEng}/>
                                                            </Col>
                                                            <Col md="6">
                                                                <h4 className="text-left">
                                                                    <Link to={`/journal/${item.journal.id}`}>
                                                                        {item.journal.titleEng}
                                                                    </Link>
                                                                </h4>
                                                                {
                                                                    item.description.length !==0 ?
                                                                        <div className="journal-text mt-4" contentEditable='false' dangerouslySetInnerHTML={{ __html: item.description[0].descriptionEng}}/> :
                                                                        <h1 className="text-center text-muted py-5">{statickLang.NOCONTENT}</h1>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                            })
                                           :
                                            <h1 className="text-center text-muted py-5">
                                                {statickLang.NOCONTENT}
                                            </h1>
                                    }
                                </Carousel>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{offset:6, size: 6}} className="text-right">
                                <Link to="/journal" >{statickLang.ALLJOURNAL}</Link>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="bg-white">
                        <Row className="row py-4">
                            <Col md="4" className="mb-4 mb-md-0">
                                <Card className="rounded-0">
                                    <CardBody>
                                        <div className="repertoire-icon mx-auto" />
                                        <h4 className="text-uppercase text-center">
                                            <Link to="/artists" className="text-decoration-none text-dark">{statickLang.ARTIST}</Link>
                                        </h4>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4" className="mb-4 mb-md-0">
                                <Card className="rounded-0">
                                    <CardBody>
                                        <div className="repertoire-icon mx-auto" />
                                        <h4 className="text-uppercase text-center">
                                            <Link to="/ticket" className="text-decoration-none text-dark">{statickLang.TICKET}</Link>
                                        </h4>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4" className="mb-4 mb-md-0">
                                <div className="card rounded-0">
                                    <div className="card-body">
                                        <div className="repertoire-icon mx-auto" />
                                        <h4 className="text-uppercase text-center">
                                            <Link to="/events" className="text-decoration-none text-dark">{statickLang.EVENTS}</Link>
                                        </h4>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="py-4">
                            <Col md="12">
                                <h4 className="m-0 pt-4 text-color f-bold text-uppercase">{
                                    statickLang.FEEDBACK
                                }</h4>
                            </Col>
                        </Row>
                        <Row className="pt-3">
                            <Col md="6">
                                <Carousel className="bg-white" showArrows={false} showStatus={false} showThumbs={false} autoPlay stopOnHover infiniteLoop={true}>
                                    {
                                        this.state.feedback.length !== 0 ?
                                            this.state.feedback.map((item,i)=>{
                                                if(lang === item.leng){
                                                    return(
                                                        <CardBody key={i} className="text-center">
                                                            <img src={statick.serverUrl+"/"+item.imageUrl} className="img-fluid rounded-circle" alt={item.userName} />
                                                            <p className="mb-0 mt-2 text-uppercase f-bold">{item.userName}</p>
                                                            <p className="mb-0 small text-uppercase f-bold text-muted">{item.userRegion}</p>
                                                            <p className="m-0 py-4 text-uppercase mx-auto">
                                                                {item.description}
                                                            </p>
                                                        </CardBody>
                                                    )
                                                }

                                            }): <h2 className="text-center text-muted py-5 my-5">{statickLang.NOCONTENT}</h2>
                                    }
                                </Carousel>
                            </Col>
                            <Col md="6" className="pt-4">
                                <AvForm onSubmit={this.handleSubmit} ref={c => (this.form = c)} className="pt-5">
                                    <p className="text-uppercase">{
                                        statickLang.FEEDBACK_2
                                    }</p>
                                    <div className="form-group">
                                        <AvField name="userName" required placeholder={statickLang.FEEDBACK_INPUT.name}/>
                                    </div>
                                    <div className="form-group">
                                        <AvField name="userContacts" required placeholder={statickLang.FEEDBACK_INPUT.email}/>
                                    </div>
                                    <div className="form-group">
                                        <AvField type="textarea" name="description" required placeholder={statickLang.FEEDBACK_INPUT.desc}/>
                                    </div>
                                    <div className="form-group text-right">
                                        <Button color="primary" className="text-capitalize">{
                                            statickLang.FORM_BTN
                                        }</Button>
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="questions">
                    <Container className="bg-white">
                        <Row>
                            <Col md="6">
                                <p className="text-muted">{statickLang.QUESTION.TITLE}</p>
                                {

                                    this.state.questions.length !==0 ?
                                        this.state.questions.map((item,i)=>{
                                            if(item.leng === lang && questionNum < 2){
                                                questionNum++;
                                                return(
                                                    <div key={i} className="border-bottom border-light py-3">
                                                        <p className="text-color f-bold">
                                                            {item.title}
                                                        </p>
                                                        <p className="text-muted">
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        })
                                        :<h2 className="text-center text-muted py-5 my-5">{statickLang.NOCONTENT}</h2>
                                }
                                <div className="py-3 text-right">
                                    <Link to="/questions">{statickLang.QUESTION.BTN}</Link>
                                </div>
                            </Col>
                            <Col md="6">
                                <p className="text-muted">{
                                    statickLang.VOTE.TITLE
                                }</p>
                                <p className="text-color f-bold py-5">
                                    {statickLang.VOTE.QUESTION}
                                </p>
                                <AvForm onSubmit={this.handleSubmit} action="#">

                                    <div className="form-group px-3">
                                        <ul className="list-unstyled">
                                            <li className="d-inline-block mr-3">
                                                <div className="radio-box">
                                                    <input type="radio" onChange={this.changProgress} id="yes" name="question" />
                                                    <label htmlFor="yes" />
                                                    <label htmlFor="yes" className="ml-1 text-color f-bold text-uppercase">{statickLang.VOTE.YES}</label>
                                                </div>
                                            </li>
                                            <li className="d-inline-block mr-3">
                                                <div className="radio-box">
                                                    <input type="radio" onChange={this.changProgress}  id="no" name="question" />
                                                    <label htmlFor="no" />
                                                    <label htmlFor="no" className="ml-1 text-color f-bold text-uppercase">{statickLang.VOTE.NO}</label>
                                                </div>
                                            </li>
                                            <li className="d-inline-block mr-3">
                                                <div className="radio-box">
                                                    <input type="radio" onChange={this.changProgress} id="indifferent" name="question" />
                                                    <label htmlFor="indifferent" />
                                                    <label htmlFor="indifferent" className="ml-1 text-color f-bold text-uppercase">{statickLang.VOTE.INDIFFERENT}</label>
                                                </div>
                                            </li>
                                        </ul>
                                        <p className="text-muted mt-5">{ statickLang.VOTE.RESULT} : {this.state.voting.summa}</p>
                                        <div className="progress">
                                            <div className="progress-bar bg-primary" style={{width: this.state.progressW+"%"}}>
                                                <span className="badge-info">{this.state.progressW+"%"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group text-right py-5 mt-5">
                                        <button type="submit" onClick={this.votingSubmit} className="btn btn-primary mt-3">{ statickLang.VOTE.BTN}</button>
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        );
    }
}

Home.propTypes = {};

export default Home;
