import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Col, Container, Row} from "reactstrap";
import './index.scss'
import {Link} from "react-router-dom";
import axios from "axios";
import statick from "../../const/index";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages:[],
        };
    }
    componentDidMount() {
        try {
            axios.get(statick.serverUrl+"/api/page")
                .then(page=>{
                    this.setState({
                        pages:page.data
                    });
                }).catch(e=>{
                    console.log(e);
            });
        }catch (e){
            console.log(e)
        }
    }
    render() {
        const statickLang = this.props.statickLang;
        const lang = this.props.lang;
        return (
            <footer className="py-5">
                <Container>
                    <Row className="pt-5">
                        <Col md="3" sm="6" className="text-center text-md-left mb-4 mb-md-0">
                            <Link to="/" className="text-decoration-none">
                                <img src="/src/images/logo.png" className="img-fluid" alt="SIRK-LOGO"/>
                                <p className="my-0 small text-white pl-4 ml-2">O'zbekiston Davlat sirki</p>
                            </Link>
                        </Col>
                        <Col md="3" sm="6" className="col-6">
                            <ul className="list-unstyled">
                                {this.state.pages.length !==0 ?
                                    this.state.pages.map((item,i)=>{
                                       if(i<5){
                                           return(
                                               <li key={i}>
                                                   <Link to={{ pathname : "/"+item.name, state: { pageId : item.id } }} className="text-white text-decoration-none small">
                                                       {
                                                           lang ==="uz"? item.titleUz :
                                                               lang ==="ru" ? item.titleRu : item.titleEng
                                                       }
                                                   </Link>
                                               </li>
                                           )
                                       }
                                    }):""

                                }
                            </ul>
                        </Col>
                        <Col md="3" sm="6" className="col-6">
                            <ul className="list-unstyled">
                                <li><Link to="/all-repertories" className="text-white text-decoration-none small">{statickLang.REPERTOIRE}</Link></li>
                                <li><Link to="/artists" className="text-white text-decoration-none small">{statickLang.ARTIST}</Link></li>
                                <li><Link to="/ticket" className="text-white text-decoration-none small">{statickLang.TICKET}</Link></li>
                                <li><Link to="/events" className="text-white text-decoration-none small">{statickLang.EVENTS}</Link></li>
                            </ul>
                        </Col>
                        <Col md="3" sm="6" className="col-12">
                            <ul className="list-unstyled pt-4">
                                <li>
                                    <a href="https://goo.gl/maps/Bw51KGjAU692" className="text-white small text-decoration-none" target="_blank">
                                        <div className="row">
                                            <div className="col-md-2 col-2 pr-0">
                                                <span className="icon icon-location" />
                                            </div>
                                            <div className="col-md-10 col-10 pl-0">
                                                <p>
                                                    {statickLang.CONTACTS.address}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:+998712443509" className="text-white small text-decoration-none">
                                        <div className="row">
                                            <div className="col-md-2 col-2  pr-0">
                                                <span className="icon icon-phone" />
                                            </div>
                                            <div className="col-md-10 col-10  pl-0">
                                                <p>
                                                    {statickLang.CONTACTS.tel}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:info@cirk.uz" className="text-white small text-decoration-none">
                                        <div className="row">
                                            <div className="col-md-2 col-2   pr-0">
                                                <span className="icon icon-envelope" />
                                            </div>
                                            <div className="col-md-10 col-10   pl-0">
                                                <p>
                                                    {statickLang.CONTACTS.mail}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

Footer.propTypes = {};

export default Footer;
